import { RouteObject } from "react-router-dom";
import { AppLayout, RouteLayoutHandle } from "@/components/layouts/AppLayout";
import { ROUTE } from "@/config/navigation";
import { RouteHeaderHandle } from "@/components/header/Header";
import { ParkPage } from "@/components/pages/park/ParkPage";
import { ReferralProgramPage } from "@/components/pages/profile/refs/ReferralProgramPage";
import { ProfilePage } from "@/components/pages/profile/Profile";
import { TasksPage } from "@/components/pages/profile/tasks/TasksPage";
import { ParkCarInfoPage } from "@/components/pages/park/CarInfo/CarInfoPage";
import { MarketPage } from "@/components/pages/market/MarketPage";
import { MarketCarInfoPage } from "@/components/pages/market/MarketCarInfoPage";
import { GamePage } from "@/components/pages/game/GamePage";
import { ExchangePage } from "@/components/pages/exchange/ExchangePage";
import { CarService } from "@/components/pages/carService/CarService";
import { TransactionsPage } from "@/components/pages/profile/transactions/TransactionsPage";
import { BalancePage } from "@/components/pages/profile/funds/BalancePage";

export const routes: AppRoute[] = [
  {
    path: "/",
    Component: AppLayout,
    // index: true,
    meta: {
      layout: "full",
    },
    children: [
      {
        path: ROUTE.PARK,

        meta: {
          translateHeaderKey: "park",
          layout: "full",
        },
        Component: ParkPage,
      },
      {
        path: ROUTE.CAR_INFO,
        meta: {
          layout: "nav",
        },
        Component: ParkCarInfoPage,
      },
      {
        path: ROUTE.SERVICE_STATION,
        meta: {
          translateHeaderKey: "serviceStation",
          layout: "full",
        },
        Component: CarService,
      },
      {
        path: ROUTE.MARKET,
        meta: {
          translateHeaderKey: "market",
          layout: "full",
        },
        Component: MarketPage,
      },
      {
        path: ROUTE.MARKET_CAR_INFO,
        meta: {
          layout: "nav",
        },
        Component: MarketCarInfoPage,
      },
      {
        path: ROUTE.EXCHANGE,
        meta: {
          translateHeaderKey: "exchange",
          layout: "full",
        },
        Component: ExchangePage,
      },
      {
        path: ROUTE.PLAY,
        Component: () => <GamePage />,
        meta: {
          layout: "empty",
        },
      },
      {
        path: ROUTE.REFS,
        Component: ReferralProgramPage,
        meta: {
          layout: "empty",
        },
      },
      {
        path: ROUTE.TRANSACTIONS,
        Component: TransactionsPage,
        meta: {
          layout: "empty",
        },
      },
      {
        path: ROUTE.PROFILE,
        Component: ProfilePage,
        meta: {
          layout: "empty",
        },
      },
      {
        path: ROUTE.TASKS,
        Component: TasksPage,
        meta: {
          layout: "empty",
        },
      },
      {
        path: ROUTE.BALANCE,
        Component: BalancePage,
        meta: {
          layout: "empty",
        },
      },
    ],
  },
];

export type AppRoute = RouteObject & {
  meta: RouteLayoutHandle & RouteHeaderHandle;
  children?: AppRoute[];
};
