import {
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useTranslation } from "react-i18next";
import { ExchangeAtTime } from "@/components/pages/exchange/types";
import CustomTooltip from "@/components/pages/exchange/Chart/CustomTooltip";

const CHART_HEIGHT = 270;
const WHITE_TEXT = "#FEFEFE";
const YELLOW_PRIMARY = "#FFD304";
const GRADIENT_COLOR = "#D9CA59";

const DATE_KEY = "date";
const VALUE_KEY = "rate";

type ChartProps = {
  data: ExchangeAtTime[];
  isLoading: boolean;
  formatter?: (arg: string) => string;
  tooltipFormatter: (arg: string) => string;
};

const Chart = ({
  data,
  isLoading,
  formatter,
  tooltipFormatter,
}: ChartProps) => {
  const { t } = useTranslation("common");

  if (data.length === 0 && !isLoading)
    return (
      <NoData>
        <p className="text-gray-light mb-1.5 text-sm">
          {t("exchange.no-data")}
        </p>
      </NoData>
    );

  return (
    <ResponsiveContainer
      width={"100%"}
      height={CHART_HEIGHT}
      className="bg-black-dark-card rounded-10 border-gray-border border text-xxs"
    >
      <AreaChart
        data={data}
        margin={{ top: 25, bottom: 15, left: 10, right: 15 }}
      >
        <defs>
          <linearGradient id="lg1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={GRADIENT_COLOR} stopOpacity={0.3} />
            <stop offset="100%" stopColor={GRADIENT_COLOR} stopOpacity={0} />
          </linearGradient>
        </defs>

        <Area
          dot={false}
          dataKey={VALUE_KEY}
          type="monotone"
          strokeWidth={2}
          fill={"url(#lg1)"}
          strokeLinecap="round"
          stroke={YELLOW_PRIMARY}
          activeDot={{ r: 7, stroke: "white", strokeWidth: 3.5 }}
        />

        <XAxis
          minTickGap={20}
          dataKey={DATE_KEY}
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tickFormatter={formatter}
          tick={{ strokeWidth: 0, fill: WHITE_TEXT }}
        />

        <YAxis
          dataKey={VALUE_KEY}
          axisLine={false}
          tickLine={false}
          tickMargin={10}
          tickFormatter={(value) => `${value}\u00A0USDT`}
          tick={{ strokeWidth: 0, fill: WHITE_TEXT }}
          domain={[0, (dataMax: number) => Math.floor(dataMax) + 1]}
        />

        <CartesianGrid
          vertical={false}
          strokeWidth={1}
          stroke={`${WHITE_TEXT}30`}
        />

        <Tooltip
          content={({ active, payload, label }) => (
            <CustomTooltip
              {...{ active, payload, label }}
              formatter={tooltipFormatter}
            />
          )}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Chart;

const NoData = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{ height: CHART_HEIGHT + "px" }}
    className="w-full bg-black-dark-card rounded-10 border-gray-border border flex justify-center items-center"
  >
    {children}
  </div>
);
