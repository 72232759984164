import ArrowIcon from "@/assets/profile/Arrow";
import { HeaderContainer } from "@/components/header/HeaderContainer";
import { type AppRoute } from "@/config/navigation";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export const HeaderScreen = (p: {
  isBack?: boolean;
  title: string;
  children?: ReactNode;
  backRoute?: AppRoute;
}) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <div className="flex items-center gap-3">
        {p.isBack && (
          <button
            onClick={() => {
              p.backRoute || navigate(-1);
            }}
            className={
              "bg-gray-10 rounded-full size-9 flex items-center justify-center"
            }
          >
            <ArrowIcon className="size-8" />
          </button>
        )}

        <h1 className={"font-montserrat font-black text-lg italic"}>
          {p.title}
        </h1>
      </div>
      {p.children}
    </HeaderContainer>
  );
};
