import { cn } from "@/utils";
import { forwardRef } from "react";

export const Input = forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input"> & {
    label?: string;
    error?: string;
    helperText?: string
  }
>(({ className, label, error, helperText, ...props }, ref) => {
  return (
    <label className="text-sm">
      {label}
      <input
        ref={ref}
        className={cn(
          "mt-2 px-3.5 py-3 rounded-12 text-sm text-white-light bg-gray-dark-input  placeholder:text-gray-middle focus-visible:outline-0",
          {
            "outline outline-1 outline-red": error,
          },
          className
        )}
        {...props}
      />
      {helperText && !error && (
        <div className="text-gray-light text-xs mt-1">{helperText}</div>
      )}
      {error && <div className="text-red text-xxs mt-1">{error}</div>}
    </label>
  );
});

Input.displayName = "Input";
