import { Button } from "@/components/ui/Button";
import { useContext, useEffect, useState } from "react";
import { GameContext } from "./GamePage";
import { formatTime, getDifferenceInSeconds } from "@/utils";

export function BusyTimerButton({
  id,
  timerEndTime,
  onSuccess,
}: {
  id: number;
  timerEndTime: string;
  onSuccess: (id: number) => void;
}) {
  const { setCarIntervals } = useContext(GameContext);

  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const differenceInSeconds = getDifferenceInSeconds(timerEndTime);
    setCarIntervals({ id, interval: differenceInSeconds });
    setTimeLeft(differenceInSeconds);
    setIsActive(true);
  }, [timerEndTime]);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (timeLeft == null) {
      return;
    }
    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (Number(prevTime) > 0) {
            return Number(prevTime) - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
    } else if (timeLeft === 0 || timeLeft < 0) {
      onSuccess(id);
      setIsActive(false);
    }

    return () => clearInterval(timer);
  }, [isActive, timeLeft]);

  return (
    <Button className="w-full py-2 px-1 rounded-8 text-sm" variant="dark">
      {(timeLeft !== null && timeLeft <= 0) || timeLeft === null
        ? "00:00"
        : formatTime(Number(timeLeft))}
    </Button>
  );
}
