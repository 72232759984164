import { useTranslation } from "react-i18next";
import { HeaderScreen } from "../HeaderScreen";
import { BasicInfo } from "./basicInfo/BasicInfo";

import { ReferralInfo } from "./ReferralInfo";
import { ReferralNetwork } from "./ReferralNetwork";
import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";

export function ReferralProgramPage() {
  const { t } = useTranslation("common");
  const title = t("profile.links.refs");

  const { data } = useQuery(apiQueries.getReferralInfo);

  return (
    <>
      <HeaderScreen isBack title={title} />
      <BasicInfo dataReferral={data} />
      <ReferralInfo />
      <ReferralNetwork referrals={data?.referrals} />
    </>
  );
}
