import { useEffect } from "react";

export function useImagePreload(
  src?: string,
  options?: {
    enabled?: boolean;
  }
) {
  useEffect(() => {
    if (!src || !options?.enabled) return;

    new Image().src = src;
  }, [src, options?.enabled]);
}
