import { cn } from "@/utils";
import st from "./style.module.css";

export function MaskIcon(p: {
  iconSrc: string;
  className?: string;
  iconClassName?: string;
  iconSize?: string;
}) {
  const style: React.CSSProperties = { maskImage: `url("${p.iconSrc}")` };

  if (p.iconSize) {
    style.maskSize = p.iconSize;
  }

  return (
    <div className={cn(st.icon, p.className)}>
      <div style={style} className={p.iconClassName} />
    </div>
  );
}
