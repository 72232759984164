import { ReferralNetworkItem } from "@/api";
import { cn } from "@/utils";
import { USDT } from "@/variables";
import { useTranslation } from "react-i18next";

export const ReferralNetwork = ({
  referrals,
}: {
  referrals: ReferralNetworkItem[] | undefined;
}) => {
  const { t } = useTranslation("common");

  //   const config = [];

  return (
    <>
      <h2 className={"text-base font-semibold mt-5"}>{t("refs.refNetwork")}</h2>
      {!referrals ||
        (referrals?.length === 0 && (
          <p className={"text-gray-middle text-xs"}>
            {t("refs.refNetworkEmpty")}
          </p>
        ))}
      {referrals && referrals.length > 0 && (
        <div className={"bg-black-dark-card rounded-10 p-4 pb-1 mb-2 mt-3"}>
          <table className={"w-full"}>
            <thead>
              <tr className={"text-gray-light font-normal text-sm"}>
                <th className={"text-start"}>{t("refs.username")}</th>
                <th className={"text-start"}>{t("refs.yourProfit")}</th>
              </tr>
            </thead>
            <tbody>
              {referrals.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={cn(
                      "text-sm",
                      index < referrals.length - 1 &&
                        "border-b border-b-white-20 text-white-light"
                    )}
                  >
                    <td className={"py-3"}>{item.name}</td>
                    <td>{item.total_referral_usdt_earnings} {USDT}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
