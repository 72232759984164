import { apiQueries } from "@/api/queries";

import { CarInfo } from "@/components/car/CarInfo";
import { useAppParams } from "@/navigation/useAppParams";
import { useQuery } from "@tanstack/react-query";
import { HeaderScreen } from "../../profile/HeaderScreen";
import { CarsCollection } from "./CarsCollection";

export function ParkCarInfoPage() {
  const { carId } = useAppParams();
  const { data: carInfo, isSuccess } = useQuery(
    apiQueries.getParkCarInfo(+carId!)
  );

  if (!isSuccess) {
    return null;
  }

  return (
    <div className="mb-6">
      <HeaderScreen title={carInfo.title} isBack />
      <CarInfo
        cover={carInfo.cover.file}
        profits={{
          perDay: {
            txy: carInfo.daily_txy_profit,
            usdt: carInfo.daily_usdt_profit,
          },
          perMonth: {
            txy: carInfo.monthly_txy_profit,
            usdt: carInfo.monthly_usdt_profit,
          },
          perYear: {
            txy: carInfo.annual_txy_profit,
            usdt: carInfo.annual_usdt_profit,
          },
        }}
        descriptionRu={carInfo.description_ru}
        descriptionUa={carInfo.description_ua}
        descriptionEn={carInfo.description_en}
        reliability={carInfo.reliability}
      />

      {/* not a free car */}
      {carId && carInfo.reliability !== 100 && (
        <CarsCollection carInfo={carInfo} />
      )}
    </div>
  );
}
