// import { useLaunchParams } from "@tma.js/sdk-react";

import { useLaunchParams } from "@telegram-apps/sdk-react";

export function usePlatform() {
  const { platform } = useLaunchParams();
  // console.log("🚀 ~ usePlatform ~ platform:", platform)
  const isApplePlatform = platform === "ios" || platform === "macos";
  return { platform, isApplePlatform };
}
