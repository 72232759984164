import { cn } from "@/utils";

export function Head(p: {
  children: React.ReactNode;
  className?: string;
  as?: keyof Pick<HTMLElementTagNameMap, "h1" | "h2" | "h3" | "h4">;
}) {
  const Elem = p.as || "h2";
  return (
    <Elem
      className={cn("font-montserrat text-lg font-black italic", p.className)}
    >
      {p.children}
    </Elem>
  );
}
