import { createRoot } from "react-dom/client";

import { Root } from "@/components/Root";

// Uncomment this import in case, you would like to develop the application even outside
// the Telegram application, just in your browser.
import "./mockEnv.ts";

import "@telegram-apps/telegram-ui/dist/styles.css";
import "./index.css";
import "./localization/i18n.ts";

import * as Sentry from "@sentry/react";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_TRACE_PROPAGATION_TARGET = import.meta.env
  .VITE_SENTRY_TRACE_PROPAGATION_TARGET;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", SENTRY_TRACE_PROPAGATION_TARGET],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
        // registration.onupdatefound = () => {
        //   const newWorker = registration.installing;
        //   if (newWorker) {
        //     newWorker.onstatechange = () => {
        //       if (
        //         newWorker.state === "installed" &&
        //         navigator.serviceWorker.controller
        //       ) {
        //         if (confirm("New version available. Refresh the page?")) {
        //           window.location.reload();
        //         }
        //       }
        //     };
        //   }
        // };
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

createRoot(document.getElementById("root")!).render(<Root />);
