import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";
import TaskCard from "./TaskCard";
import { useTranslation } from "react-i18next";
import { Lang } from "@/localization/localizationConfig";
import { HeaderScreen } from "../HeaderScreen";
import { BalanceField } from "../../../BalanceField";

export function TasksPage() {
  const { data: tasksData } = useQuery(apiQueries.getUserTasks);
  // console.log("🚀 ~ TasksPage ~ tasksData:", tasksData);
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const lang = language.split("-")[0];
  const title = t("tasks.title");

  return (
    <div className="mb-2">
      <HeaderScreen isBack title={title}>
        <BalanceField />
      </HeaderScreen>

      <ul className="flex flex-col gap-3">
        {tasksData?.map((item) => (
          <li key={item.id}>
            <TaskCard
              data={item}
              lang={lang as Lang}
              isPerformedText={t("tasks.isPerformed")}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
