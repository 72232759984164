import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { cn } from "@/utils";

export const SideBar = ({
  isOpen,
  children,
  setIsOpen,
  withImage = false,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  setIsOpen: (value: boolean) => void;
  withImage?: boolean;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [touchStartY, setTouchStartY] = useState<number | null>(null); // Хранит начальную точку касания
  const [touchCurrentY, setTouchCurrentY] = useState<number | null>(null); // Текущая поз

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);

      setTimeout(() => {
        setIsAnimating(true);
      }, 20);
    } else {
      setIsAnimating(false);

      const timer = setTimeout(() => {
        setIsMounted(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isMounted) return null;

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchCurrentY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (touchStartY !== null && touchCurrentY !== null) {
      const touchDifference = touchCurrentY - touchStartY;

      if (touchDifference > 50) {
        setIsOpen(false);
      }
    }

    // Сбрасываем состояние касаний
    setTouchStartY(null);
    setTouchCurrentY(null);
  };

  return createPortal(
    <>
      <div
        className={cn(
          "fixed inset-0 backdrop-blur-md w-full z-40 bg-gray-dark-input bg-opacity-30",
          isOpen ? "block" : "hidden"
        )}
        onClick={() => setIsOpen(false)}
      />

      <div
        className={cn(
          "fixed bottom-0 right-0 w-full rounded-t-30 z-40 bg-white-light transition-transform duration-500",
          isAnimating ? "translate-y-0" : "translate-y-full",
          { "p-3": !withImage }
        )}
      >
        <div
          className="h-12 absolute top-0 left-0 right-0"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="w-10 h-1 rounded-6 bg-gray-light absolute top-3 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
        {children}
      </div>
    </>,
    document.querySelector("#root")!
  );
};
