import { localeRU, localeUA, localeEN } from "./locales";

export type Lang = "ru" | "ua" | "en";

export const LANGS: Record<Lang, Lang> = {
  ru: "ru",
  ua: "ua",
  en: "en",
};

export const DEFAULT_LANG = LANGS.en;

export type TranslateNamespaces = typeof localeRU;

export const translateResources = {
  ru: localeRU,
  ua: localeUA,
  en: localeEN,
} satisfies Record<Lang, TranslateNamespaces>;
