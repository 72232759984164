import { routes } from "@/navigation/routes";
import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { Link } from "../Link/Link";
import { ROUTE } from "@/config/navigation";
import personImg from "./user.png";
import { TranslateNamespaces } from "@/localization/localizationConfig";
import { useTranslation } from "react-i18next";
import { BalanceField } from "../BalanceField";
import { HeaderContainer } from "./HeaderContainer";

export function Header() {
  const loc = useLocation();

  const lastMatchedRouteHeader = useMemo(
    () =>
      matchRoutes(routes, loc.pathname)!.at(-1)!.route.meta.translateHeaderKey!,
    [loc.pathname]
  );

  const { t } = useTranslation("common");

  return (
    <HeaderContainer>
      <h1 className="font-montserrat text-lg font-black italic">
        {t(`header.titles.${lastMatchedRouteHeader}`)}
      </h1>

      <div className="flex items-center gap-2 h-9">
        <BalanceField />
        <Link
          style={{
            backgroundImage: `url(${personImg})`,
            backgroundSize: "42%",
          }}
          className="block h-full aspect-square bg-center bg-no-repeat bg-gray-10 rounded-full"
          to={ROUTE.PROFILE}
        />
      </div>
    </HeaderContainer>
  );
}

export type RouteHeaderHandle = {
  translateHeaderKey?: keyof TranslateNamespaces["common"]["header"]["titles"];
};
