import { Outlet, matchRoutes, useLocation } from "react-router-dom";
import { AppNavigation } from "../navigation/Navigation";
import { useLayoutEffect, useMemo } from "react";
import { routes } from "@/navigation/routes";
import { Header } from "../header/Header";
import { useSnackbar } from "../ui/SnackBar/SnackbarContext";
import { useQueryClient } from "@tanstack/react-query";
import { ApiError } from "@/api";

export function AppLayout() {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const snackBar = useSnackbar();

  useLayoutEffect(() => {
    function showErrorSnackbar(apiError: ApiError) {
      snackBar.setShowWithProps({
        children: apiError.errors[0].detail,
      });
      return false;
    }

    queryClient.setDefaultOptions({
      queries: {
        throwOnError: (err) => showErrorSnackbar(err as unknown as ApiError),
      },
      mutations: {
        throwOnError: (err) => showErrorSnackbar(err as unknown as ApiError),
      },
    });
  }, []);

  const lastMatchedRoute = useMemo(
    () => matchRoutes(routes, pathname)!.at(-1)!.route,
    [pathname]
  );

  const lastMatchedRouteLayout = lastMatchedRoute.meta.layout;

  const isHeaderVisible = (["full", "header"] satisfies Layout[]).includes(
    //@ts-expect-error ts(2322)
    lastMatchedRouteLayout
  );

  const isNavigationVisible = (["full", "nav"] satisfies Layout[]).includes(
    //@ts-expect-error ts(2322)
    lastMatchedRouteLayout
  );

  return (
    <main className="font-inter flex flex-col min-h-svh bg-black-footer px-layout text-base">
      {isHeaderVisible && <Header />}
      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
      {isNavigationVisible && <AppNavigation />}
    </main>
  );
}

export type Layout = "header" | "nav" | "empty" | "full";
export type RouteLayoutHandle = {
  layout: Layout;
};
