import appTranslations from "./ru/localeRu.json";

export const localeRU = {
  common: appTranslations,
};

import appUaTranslations from "./ua/localeUa.json";

export const localeUA = {
  common: appUaTranslations,
};

import appEnTranslations from "./en/localeEn.json";

export const localeEN = {
  common: appEnTranslations,
};
