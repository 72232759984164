import { useIntegration } from "@telegram-apps/react-router-integration";
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  useBackButton,
  useLaunchParams,
  useMiniApp,
  useThemeParams,
  useViewport,
} from "@telegram-apps/sdk-react";
import { AppRoot } from "@telegram-apps/telegram-ui";
import { type FC, useEffect, useMemo } from "react";
import { Router, useRoutes } from "react-router-dom";

import { routes } from "@/navigation/routes.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { saveToken } from "@/api/apiUtils";
import { ROUTE } from "@/config/navigation";
import { SnackbarProvider } from "./ui/SnackBar/SnackbarContext";
// import { useTranslation } from "react-i18next";
import { useCheckDetectedLang } from "@/localization/useCheckDetectedLang";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      retry: 3,
    },
  },
});

export const App: FC = () => {
  const lp = useLaunchParams();
  const miniApp = useMiniApp();
  const themeParams = useThemeParams();
  const viewport = useViewport();
  const backBtn = useBackButton();

  useCheckDetectedLang();

  useEffect(() => {
    miniApp.setHeaderColor("#242424");
    miniApp.setBgColor("#242424");

    saveToken(lp.initDataRaw!);
  }, []);

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  // Create a new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator("app-navigation-state"), []);
  const [location, reactNavigator] = useIntegration(navigator);
  // console.log("🚀 ~ location:", location, reactNavigator)

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  useEffect(() => {
    backBtn.hide();
    location.pathname === "/" && reactNavigator.replace(ROUTE.MARKET);
  }, [location.pathname]);

  return (
    <AppRoot
      style={{
        //@ts-expect-error no types for tg vars
        "--tg-theme-bg-color": "#242424",
        "--tg-theme-text-color": "#FEFEFE",
        // "--tgui--surface_dark": "#242424",
        "--tgui--font-family": "Inter, sans-serif",
      }}
      // appearance={miniApp.isDark ? 'dark' : 'light'}
      appearance={"dark"}
      platform={["macos", "ios"].includes(lp.platform) ? "ios" : "base"}
    >
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <Router location={location} navigator={reactNavigator}>
            <Route />
          </Router>
        </SnackbarProvider>
      </QueryClientProvider>
    </AppRoot>
  );
};

function Route() {
  return useRoutes(routes);
}
