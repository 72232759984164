import { DEFAULT_LANG } from "@/localization/localizationConfig";
import clsx from "clsx";
import { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...args: ClassValue[]) {
  return twMerge(clsx(args));
}

export function preloadImages(srcs: string[]) {
  srcs.forEach((src) => {
    new Image().src = src;
  });
}

export function capitalize<T extends string>(str: T) {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
}

export function getSavedLang() {
  return localStorage.getItem("i18nextLng") || DEFAULT_LANG;
}

export const formatTime = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

export const getDifferenceInSeconds = (time: string) => {
  const targetTimeUTC = new Date(time);

  const currentTime = new Date();

  const differenceInMilliseconds =
    targetTimeUTC.getTime() - currentTime.getTime();

  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

  return differenceInSeconds;
};


const hoursName = {
  ru: {
    hour: "час",
    hour_a: "часа",
    hour_ov: "часов",
  },
  ua: {
    hour: "година",
    hour_a: "години",
    hour_ov: "годин",
  },
};

export function getHoursDeclension(hours: number, language: string) {
  const ceilHours = Math.ceil(hours);
  if (language === "ru" || language === "ua") {
    if (hours < 1) {
      return `<1 ${hoursName[language].hour_a}`;
    } else if (ceilHours % 10 === 1 && ceilHours % 100 !== 11) {
      return `${ceilHours} ${hoursName[language].hour}`;
    } else if (
      ceilHours % 10 >= 2 &&
      ceilHours % 10 <= 4 &&
      (ceilHours % 100 < 12 || ceilHours % 100 > 14)
    ) {
      return `${ceilHours} ${hoursName[language].hour_a}`;
    } else {
      return `${ceilHours} ${hoursName[language].hour_ov}`;
    }
  } else {
    if (hours < 1) {
      return "<1 hour";
    } else {
      return `${ceilHours} hour${ceilHours !== 1 ? "s" : ""}`;
    }
  }
}
