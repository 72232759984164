import { useTranslation } from "react-i18next";
import { HeaderScreen } from "../HeaderScreen";
import { BalanceField } from "../../../BalanceField";
import { useState } from "react";
import { Tabs } from "@/components/ui/Tabs";
import { RefillForm } from "./RefillForm";
import { TransferForm } from "./TransferForm";

export function BalancePage() {
  const { t } = useTranslation("common");

  const [status, setStatus] = useState<"refill" | "transfer">("refill");

  const tabs = [
    { label: t("balance.refill.title"), id: "refill" },
    { label: t("balance.transfer.title"), id: "transfer" },
  ];
  return (
    <div className="mb-2">
      <HeaderScreen isBack title={t("balance.myBalance")}>
        <BalanceField />
      </HeaderScreen>

      <div>
        <Tabs
          tabs={tabs}
          activeTab={status}
          onChange={(tab) => {
            if (tab === "refill" || tab === "transfer") {
              setStatus(tab);
            }
          }}
        />

        {status === "refill" && <RefillForm />}
        {status === "transfer" && <TransferForm />}
      </div>
    </div>
  );
}
