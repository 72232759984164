import { Task } from "@/api";
import { CardProfile } from "@/components/cards/CardProfile/CardProfile";
// import { TaskItem } from "@/utils/types";
import type { Lang } from "@/localization/localizationConfig";

function TaskCard({
  data,
  lang,
  isPerformedText,
}: {
  data: Task;
  lang: Lang;
  isPerformedText: string;
}) {
  const {
    task,
    is_performed: isPerformed,
    current_progress: currentProgress,
  } = data;

  return (
    <CardProfile className="p-3 flex gap-4 rounded-10 min-h-32">
      <div className="bg-gray-dark-image rounded-12 overflow-hidden size-24 shrink-0 flex items-center justify-center">
        <img src={task.cover.file} className="object-contain size-24" />
      </div>

      <div className="h-full w-full flex flex-col min-h-24">
        <h2 className="font-bold mb-1">{task[`title_${lang}`]}</h2>
        <p className="text-gray-light text-sm flex-1 ">
          {task[`description_${lang}`]}
        </p>
        {isPerformed && (
          <div className="bg-green-completed text-center text-sm p-1 w-full rounded-6 mt-2">
            {isPerformedText}
          </div>
        )}

        {!isPerformed && (
          <div className="mt-2">
            <div className="text-xs text-right mb-1">
              {Number(currentProgress)}/{Number(task.max_progress)}
            </div>
            <div className="w-full h-1.5 rounded-30 bg-white-light">
              <div
                className="bg-green-completed h-1.5 rounded-30"
                style={{
                  width: `${(currentProgress / task.max_progress) * 100}%`,
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </CardProfile>
  );
}

export default TaskCard;
