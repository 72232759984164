import { ParamsGetMarketCars, ResponseGetMarketCars } from "@/api";
import { useInvalidateQueriesByKeys } from "@/api/apiUtils";
import { apiMutations, apiQueries, QueryKey } from "@/api/queries";
import { CarProfitList } from "@/components/car/CarProfitList";
import { CardBlocker } from "@/components/cards/CardBlocker/CardBlocker";
import { Link } from "@/components/Link/Link";
import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import {
  generateDynamicRoute as generateDynamicRoutePath,
  ROUTE,
} from "@/config/navigation";
import { TranslateNamespaces } from "@/localization/localizationConfig";
import { TXY, USDT } from "@/variables";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { LinkProps, useNavigate, useSearchParams } from "react-router-dom";

type OrderingSearchParams = Extract<
  Required<ParamsGetMarketCars>["ordering"],
  "discount_price" | "-discount_price" | "daily_txy_profit" | "reliability"
>;

export function MarketPage() {
  const [currentOrdering] = useMarketOrderingSearchParams();
  const { t } = useTranslation("common");

  const {
    data: marketCars,
    isSuccess,
    refetch: refetchMarketCars,
  } = useQuery(
    apiQueries.getMarketCars({
      ordering: currentOrdering,
      size: 100,
    })
  );

  const { userProfile } = useInvalidateQueriesByKeys([QueryKey.userProfile]);

  const { mutateAsync: purchaseCar, isPending: isPendingPurchase } =
    useMutation(apiMutations.purchaseCar);

  const [buyCarSideBar, setSideBarBuyCar] = useState<
    ResponseGetMarketCars["results"][number] | null
  >(null);

  const [isOpen, setIsOpen] = useState(false);

  async function purchaseCarHandler(id: number) {
    await purchaseCar(id);
    refetchMarketCars();
    userProfile.invalidate();
    setIsOpen(false);
  }

  return (
    <div>
      <OrderingSelect />

      {isSuccess && (
        <>
          <ul className="space-y-2 mt-3">
            {marketCars?.results.map((car) => (
              <CardMarketCar
                key={car.id}
                onBuyClick={() => {
                  setSideBarBuyCar(car);
                  setIsOpen(true);
                }}
                car={car}
              />
            ))}
          </ul>
          {Boolean(buyCarSideBar) && (
            <SideBar isOpen={isOpen} setIsOpen={setIsOpen}>
              <h2 className="font-montserrat font-black italic text-lg mt-2">
                {t("actions.buy")} {buyCarSideBar!.title} ?
              </h2>
              <h3 className="text-sm font-semibold mb-2 mt-3">
                {t("car.profit")}:
              </h3>
              <CarProfitList
                noIcons
                className="text-sm font-normal text-gray-dark"
                profits={{
                  perDay: {
                    txy: buyCarSideBar!.daily_txy_profit,
                    usdt: buyCarSideBar!.daily_usdt_profit,
                  },
                  perMonth: {
                    txy: buyCarSideBar!.monthly_txy_profit,
                    usdt: buyCarSideBar!.monthly_usdt_profit,
                  },
                  perYear: {
                    txy: buyCarSideBar!.annual_txy_profit,
                    usdt: buyCarSideBar!.annual_usdt_profit,
                  },
                }}
              />
              <hr className="my-3 border-black-12" />
              <h3 className="text-sm font-semibold mt-3">
                {t("car.reliability")}:
              </h3>
              <span className="text-sm font-normal text-gray-dark">
                {buyCarSideBar!.reliability}%
              </span>

              <img
                className="w-full aspect-video object-contain"
                src={buyCarSideBar!.cover.file}
                alt={buyCarSideBar!.title}
              />

              <Button
                disabled={isPendingPurchase}
                onClick={() => purchaseCarHandler(buyCarSideBar!.id)}
                className="w-full"
                variant="dark"
              >
                {+buyCarSideBar!.discount_price === 0
                  ? t("actions.getForFree")
                  : `${t("actions.pay")} ${
                      buyCarSideBar!.discount_price
                    } ${TXY}`}
              </Button>
            </SideBar>
          )}
        </>
      )}
    </div>
  );
}

function CardMarketCar(p: {
  car: ResponseGetMarketCars["results"][number];
  onBuyClick: () => void;
}) {
  const { discount_price, is_purchased, full_price, is_locked } = p.car;
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const [LinkResolver, resolverProps] = is_locked
    ? [Fragment, {}]
    : [
        Link,
        {
          to: generateDynamicRoutePath(
            is_purchased ? ROUTE.CAR_INFO : ROUTE.MARKET_CAR_INFO,
            p.car.id
          ),
        } satisfies LinkProps,
      ];

  return (
    <li className="relative rounded-10 bg-gray-10 p-4 overflow-hidden border border-card-border">
      {/* @ts-expect-error no resolve props type */}
      <LinkResolver {...resolverProps}>
        <h2 className="flex items-center justify-between text-yellow-primary text-lg font-bold">
          {p.car.title} ({p.car.current_count_per_user}/
          {p.car.max_count_per_user ?? <>&#8734;</>})
          <svg
            className="size-9 p-2 bg-yellow-primary rounded-full"
            width="20"
            height="13"
            viewBox="0 0 20 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2432 7.08827C19.5681 6.76337 19.5681 6.23662 19.2432 5.91173L13.9488 0.617324C13.6239 0.292433 13.0972 0.292433 12.7723 0.617324C12.4474 0.942215 12.4474 1.46897 12.7723 1.79386L17.4784 6.5L12.7723 11.2061C12.4474 11.531 12.4474 12.0578 12.7723 12.3827C13.0972 12.7076 13.6239 12.7076 13.9488 12.3827L19.2432 7.08827ZM0.345093 7.33194L18.655 7.33193L18.655 5.66806L0.345093 5.66806L0.345093 7.33194Z"
              fill="#111112"
            />
          </svg>
        </h2>
        <div className="text-sm font-medium leading-normal">
          <span className="text-gray-light">{t("car.profit")}: </span>
          {`${t("car.upTo")} ${p.car.annual_txy_profit} ${TXY} (${
            p.car.annual_usdt_profit
          } ${USDT})`}
          <br />
          <span className="text-gray-light">{t("car.reliability")}: </span>
          {p.car.reliability}%
        </div>

        <div className="relative">
          <div className="absolute left-0 right-0 top-6 mx-auto w-5/6 h-1/5 bg-yellow-primary bg-opacity-45 rounded-[50%] blur-2xl" />
          <img
            className="relative z-10 aspect-video -my-4 w-full object-contain"
            src={p.car.cover.file}
            alt={p.car.title}
          />
        </div>
      </LinkResolver>

      <Button
        className="w-full whitespace-nowrap rounded-8"
        variant={is_purchased ? "green" : "dark"}
        onClick={
          is_purchased
            ? () => navigate(resolverProps.to!)
            : !is_locked
            ? p.onBuyClick
            : undefined
        }
      >
        {is_purchased ? (
          t("market.inPark")
        ) : +discount_price === 0 ? (
          t("actions.getForFree")
        ) : (
          <>
            {t("actions.buyFor")} {discount_price} {TXY}
            {full_price !== discount_price && (
              <span className="line-through pl-1 text-gray-middle text-opacity-55">
                {full_price} {TXY}
              </span>
            )}
          </>
        )}
      </Button>
      {is_locked && (
        <CardBlocker
          variant="needUnlock"
          tasksToUnlock={p.car.tasks_to_unlock}
        />
      )}
    </li>
  );
}

const DEFAULT_PARAMS: { ordering: OrderingSearchParams } = {
  ordering: "discount_price",
};

const ORDERING_TITLE_BY_SEARCH_PARAM = {
  discount_price: "discountPrice",
  "-discount_price": "-discountPrice",
  daily_txy_profit: "dailyTxyProfit",
  reliability: "reliability",
} satisfies Record<
  OrderingSearchParams,
  keyof TranslateNamespaces["common"]["market"]["ordering"]
>;

const ORDERING_ENTRIES = Object.entries(ORDERING_TITLE_BY_SEARCH_PARAM) as [
  keyof typeof ORDERING_TITLE_BY_SEARCH_PARAM,
  (typeof ORDERING_TITLE_BY_SEARCH_PARAM)[keyof typeof ORDERING_TITLE_BY_SEARCH_PARAM]
][];

function useMarketOrderingSearchParams(): [
  OrderingSearchParams,
  (value: OrderingSearchParams) => void
] {
  const [searchParams, setSearchParams] = useSearchParams(DEFAULT_PARAMS);

  const currentOrdering = searchParams.get("ordering") as OrderingSearchParams;

  function setOrdering(value: OrderingSearchParams) {
    setSearchParams({ ordering: value });
  }

  return [currentOrdering, setOrdering];
}

function OrderingSelect() {
  const [currentOrdering, setOrdering] = useMarketOrderingSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("common");

  return (
    <>
      <button
        className="flex items-center justify-between bg-gray-10 rounded-10 font-medium px-4 py-2 text-xs w-full"
        onClick={() => setIsOpen(true)}
      >
        {`${t("market.sortBy")}: ${t(
          `market.ordering.${ORDERING_TITLE_BY_SEARCH_PARAM[currentOrdering]}`
        )}`}

        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1506 15.6987L13.8493 12L10.1506 8.30133"
            stroke="#FEFEFE"
            strokeWidth="1.2329"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <SideBar {...{ isOpen, setIsOpen }}>
        <h2 className="font-montserrat font-black italic text-lg text-center mt-3 mb-2">
          {t("market.sortBy")}
        </h2>
        <ul className="divide-y divide-black-dark divide-opacity-10">
          {ORDERING_ENTRIES.map(([orderingParam, translateTitleField]) => {
            const _isActive = currentOrdering === orderingParam;
            return (
              <li key={orderingParam}>
                <button
                  className="flex w-full items-center justify-between py-3 text-sm text-gray-dark-input pr-2"
                  onClick={() => {
                    setOrdering(orderingParam);
                    setIsOpen(false);
                  }}
                >
                  {t(`market.ordering.${translateTitleField}`)}
                  {_isActive && (
                    <svg
                      className="w-3 h-auto"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7 1.20039C13.3 0.800391 12.7 0.800391 12.3 1.20039L4.8 8.70039L1.7 5.60039C1.3 5.20039 0.7 5.20039 0.3 5.60039C-0.1 6.00039 -0.1 6.60039 0.3 7.00039L4.1 10.8004C4.3 11.0004 4.5 11.1004 4.8 11.1004C5.1 11.1004 5.3 11.0004 5.5 10.8004L13.7 2.60039C14.1 2.20039 14.1 1.60039 13.7 1.20039Z"
                        fill="#2AA415"
                      />
                    </svg>
                  )}
                </button>
              </li>
            );
          })}
        </ul>
      </SideBar>
    </>
  );
}
