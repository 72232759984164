import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LANGS } from "./localizationConfig";
import { getSavedLang } from "@/utils";

/**
 Checks and replaces the stored language if it does not match the allowed ones
 * @returns {void}
 */
export function useCheckDetectedLang() {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!i18n.isInitialized) return;

    const savedLang = getSavedLang()!;

    const matchedLang = Object.values(LANGS).find((lang) =>
      savedLang.includes(lang)
    );

    if (i18n.language !== matchedLang)
      i18n.changeLanguage(matchedLang || i18n.resolvedLanguage);
  }, [i18n.isInitialized]);
}
