import { UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";
import { api, ApiError, ParamsGetMarketCars } from ".";

export enum QueryKey {
  gameCars = "gameCars",
  gameEarnings = "gameEarnings",
  userProfile = "userProfile",
  userParkCars = "userParkCars",
  marketParkCars = "marketParkCars",
  userTasks = "userTasks",
  parkCarInfo = "parkCarInfo",
  parkCarsCollection = "parkCarsCollection",
  marketCarInfo = "marketCarInfo",
  exchanges = "exchanges",
  currentRate = "currentRate",
  carsService = "carsService",
  referralInfo = "referralInfo",
  transactions = "transactions",
}
export const apiQueries = {
  getUserParkCars: {
    queryKey: [QueryKey.userParkCars],
    queryFn: () =>
      api.getUserParkCars({
        size: 100,
        ordering: "car__discount_price",
      }),
  },

  getMarketCars(params?: ParamsGetMarketCars) {
    return {
      queryKey: [QueryKey.userParkCars, params],
      queryFn: () => api.getMarketCars(params),
    };
  },

  getUserTasks: {
    queryKey: [QueryKey.userTasks],
    queryFn: () => api.getUserTasks(),
  },

  getProfile: {
    queryKey: [QueryKey.userProfile],
    queryFn: () => api.getUserProfile(),
  },

  getParkCarInfo(params: Parameters<typeof api.getParkCarInfo>[0]) {
    return {
      queryKey: [QueryKey.parkCarInfo, params],
      queryFn: () => api.getParkCarInfo(params),
    };
  },

  getParkCarsCollection({
    id,
    params,
  }: {
    id: number;
    params: Parameters<typeof api.getParkCarsCollection>[1];
  }) {
    return {
      queryKey: [QueryKey.parkCarsCollection, id, params],
      queryFn: () => api.getParkCarsCollection(id, params),
    };
  },

  getMarketCarInfo(params: Parameters<typeof api.getMarketCarInfo>[0]) {
    return {
      queryKey: [QueryKey.marketCarInfo, params],
      queryFn: () => api.getMarketCarInfo(params),
    };
  },

  getGameCars(params: Parameters<typeof api.getGameCars>[0]) {
    return {
      queryKey: [QueryKey.gameCars, params],
      queryFn: () => api.getGameCars(params),
    };
  },

  getGameEarnings() {
    return {
      queryKey: [QueryKey.gameEarnings],
      queryFn: () => api.getGameEarnings(),
    };
  },

  getExchanges(params: Parameters<typeof api.getExchanges>[0]) {
    return {
      queryKey: [QueryKey.exchanges, params],
      queryFn: () => api.getExchanges(params),
    };
  },

  getCurrentRate: {
    queryKey: [QueryKey.currentRate],
    queryFn: () => api.getCurrentRate(),
  },

  getCarsService: {
    queryKey: [QueryKey.carsService],
    queryFn: () => api.getCarsService(),
  },
  getReferralInfo: {
    queryKey: [QueryKey.referralInfo],
    queryFn: () => api.getReferralInfo(),
  },
  getAvatars: {
    queryKey: ["avatars"],
    queryFn: () => api.getAvatars(),
  },
  getReferralsStatuses: {
    queryKey: ["referralsStatuses"],
    queryFn: () => api.getReferralsStatuses(),
  },
  getTransactions(params: Parameters<typeof api.getTransactions>[0]) {
    return {
      queryKey: [QueryKey.transactions, params],
      queryFn: () => api.getTransactions(params),
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<string, UseQueryOptions | ((args: any) => UseQueryOptions)>;

export const apiMutations = {
  collectIncomeFromGar: {
    mutationFn: (id: number) => api.collectIncomeFromGar(id),
  },

  sendCarForWork: {
    mutationFn: (id: number) => api.sendCarForWork(id),
  },

  transferToBalance: {
    mutationFn: () => api.transferToBalance(),
  },

  requestWithdrawal: {
    mutationFn: (data) => api.requestWithdrawal(data),
  },

  addSticker: {
    mutationFn: (id: number) => api.addSticker(id),
  },

  purchaseCar: {
    mutationFn: (id: number) => api.purchaseCar(id),
  },

  changeAvatar: {
    mutationFn: (id: string) => api.changeAvatar(id),
  },

  // /api/v1/users/markets/cars/{id}/purchase

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<string, UseMutationOptions<any, ApiError, any>>;
// export const apiInfiniteQueries = {
//   getLastBets: {
//     queryKey: ["infiniteLastUsersBets"],
//     queryFn: (params) =>
//       api.getLastBets({
//         ordering: "-date",
//         page: params.pageParam as number,
//         size: 25,
//       }),
//     initialPageParam: 1,
//     // @ts-expect-error too different type
//     getNextPageParam: (
//       lastPage: ResponseGetLastBets,
//       _,
//       lastPageParam: number
//     ) => {
//       return lastPageParam < 4 && lastPage.pages > lastPageParam
//         ? lastPageParam + 1
//         : null;
//     },
//   },

//   getUserLastGames: {
//     queryKey: ["infiniteUserLastGames"],
//     initialPageParam: 1,
//     queryFn: (params) =>
//       api.getUserLastGames({
//         ordering: "-created_at",
//         status: "ENDED",
//         page: params.pageParam as number,
//         size: 25,
//       }),
//     // @ts-expect-error too different type
//     getNextPageParam: (
//       lastPage: ResponseGetUserLastGames,
//       _,
//       lastPageParam: number
//     ) => {
//       return lastPageParam < 4 && lastPage.pages > lastPageParam
//         ? lastPageParam + 1
//         : null;
//     },
//   },

//   getFrens: {
//     queryKey: ["infiniteFrens"],
//     initialPageParam: 1,
//     // @ts-expect-error too different type
//     getNextPageParam: (
//       lastPage: ResponseGetFrens,
//       _,
//       lastPageParam: number
//     ) => {
//       return lastPage.pages > lastPageParam ? lastPageParam + 1 : null;
//     },
//     queryFn: (params) =>
//       api.getFrens({ page: params.pageParam as number, size: 25 }),
//   },
// } satisfies Record<string, UseInfiniteQueryOptions>;
