/**
 * Container with height of fixed header and relative element (offset).
 */
export function HeaderContainer(p: { children: React.ReactNode }) {
  return (
    <>
      <header className="flex items-center justify-between fixed top-0 left-0 right-0 h-header px-layout z-20 bg-black-footer">
        {p.children}
      </header>
      <div className="h-header mb-2" />
    </>
  );
}
