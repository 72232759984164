// import { Translation, useTranslation } from "react-i18next";

import { useTranslation } from "react-i18next";
// import keysImg from "./keys.png";
// import lockImg from "./lock.png";
import { imgLock, imgWrenches } from "@/assets";
import { ResponseGetMarketCars } from "@/api";
import { cn } from "@/utils";
// import { useApiTranslation_depricated } from "@/localization/useApiTranslation";
import { Lang } from "@/localization/localizationConfig";

export function CardBlocker(p: CardBlockerProps) {
  const { t } = useTranslation("common");

  const head =
    p.variant === "needRepair"
      ? t("cardBlockers.needRepair.head", {
          carName: p.carName,
        })
      : t("cardBlockers.needUnlock.head");

  const body =
    p.variant === "needRepair"
      ? t("cardBlockers.needRepair.body")
      : t("cardBlockers.needUnlock.body");

  return (
    <div className="bg-black-dark-card bg-opacity-55 backdrop-blur-md absolute inset-0 z-10 flex flex-col justify-center items-center p-7 pb-4">
      <div
        className="size-14 bg-yellow-primary rounded-full bg-center bg-no-repeat bg-contain mb-5 shrink-0"
        style={{
          backgroundImage: `url(${
            p.variant === "needUnlock" ? imgLock : imgWrenches
          })`,
          backgroundSize: p.variant === "needUnlock" ? "45%" : "85%",
        }}
      />
      <h3 className="font-bold text-lg mb-2 text-center">{head}</h3>
      <p
        className={cn("text-sm text-center leading-snug", {
          "text-gray-light": p.variant === "needUnlock",
        })}
      >
        {body}
      </p>

      {p.variant === "needUnlock" && (
        <TasksToUnlockList list={p.tasksToUnlock} />
      )}
    </div>
  );
}

export type CardBlockerProps =
  | {
      variant: "needRepair";
      carName: string;
    }
  | VariantLocked;

type VariantLocked = {
  variant: "needUnlock";
  tasksToUnlock: ResponseGetMarketCars["results"][number]["tasks_to_unlock"];
};

function TasksToUnlockList(p: { list: VariantLocked["tasksToUnlock"] }) {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  return (
    <ul className="mt-8 px-4 space-y-2">
      {p.list.map((task) => (
        <li key={task.id}>
          <p className="text-sm leading-snug">
            <span className="font-bold">{t("tasks.task")}: </span>
            {task[`description_${language as Lang}`]}
          </p>
        </li>
      ))}
    </ul>
  );
}
