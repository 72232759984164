import { imgCoinTxy66 } from "@/assets";

export function CoinIcon() {
  return (
    <div
      style={{
        backgroundImage: `url(${imgCoinTxy66})`,
      }}
      className={"bg-no-repeat bg-cover bg-center size-6 rounded-full"}
    />
  );
}
