import { Query, useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "./queries";
import { useMemo } from "react";

export function getSavedToken() {
  return localStorage.getItem("initDataRaw")!;
}

export function saveToken(token: string) {
  localStorage.setItem("initDataRaw", token);
}

export function invalidateByPredicate(
  firstKeys: (string | number)[],
  onlyActive = true
) {
  return function (query: Query) {
    if (onlyActive && !query.isActive()) {
      return false;
    }

    return firstKeys.some((k) => query.queryKey[0] === k);
  };
}

export function useInvalidateQueriesByKeys<T extends QueryKey>(
  keys: T[],
  deps: unknown[] = []
) {
  const qc = useQueryClient();

  return useMemo(
    () =>
      Object.fromEntries(
        keys.map((k) => [
          k,
          { invalidate: () => qc.invalidateQueries({ queryKey: [k] }) },
        ])
      ) as Record<T, { invalidate: () => Promise<void> }>,
    deps
  );
}
