import { GameCar } from "@/api";
import { Button } from "@/components/ui/Button";
import { TXY } from "@/variables";
import { BusyTimerButton } from "./BusyTimerButton";
import { useTranslation } from "react-i18next";
import { WaitForNextWorkButton } from "./WaitForNextWorkButton";
import { cn } from "@/utils";
import { imgWrenches } from "@/assets";

export function ParkCard({
  car,
  onCollectIncome,
  onSendForWork,
  onSuccessWork,
  onSuccessCars,
}: {
  car: GameCar;
  onCollectIncome: (id: number) => void;
  onSendForWork: (id: number) => void;
  onSuccessWork: (id: number) => void;
  onSuccessCars: () => void;
}) {
  const { t } = useTranslation("common");

  const isFree =
    !car.available_txy_to_collect && !car.ride_end_time && !car.ride_start_time;
  const isWithProfit = !car.ride_end_time && car.available_txy_to_collect;
  const isBusy = car.ride_end_time && !car.available_txy_to_collect;
  const isBroken =
    car.is_broken && !car.ride_end_time && !car.available_txy_to_collect;

  const isWorkedDuringLast24h =
    car.ride_start_time && !car.available_txy_to_collect;

  return (
    <li className="relative">
      {isBroken && (
        <div className="absolute inset-0 z-10 flex flex-col justify-center items-center">
          <div
            className="size-12 bg-yellow-primary rounded-full bg-center bg-no-repeat bg-contain mb-4 shrink-0"
            style={{
              backgroundImage: `url(${imgWrenches})`,
              backgroundSize: "75%",
            }}
          />
          <p className="font-inter text-xs font-semibold flex flex-col items-center">
            <span> {car.car.title}</span>
            <span>{t("game.footer.broken")}</span>
          </p>
        </div>
      )}
      <div
        className={cn(
          { "blur-lg pointer-events-none": isBroken },
          "bg-gray-light-01 p-3 rounded-10 font-inter text-xs"
        )}
      >
        <h3 className="font-bold">
          {car.sequence_id}.{car.car.title}
        </h3>

        <div className="bg-gray-light-02 rounded-8 my-1.5 py-1.5">
          <img src={car.cover.file} />
        </div>

        {isFree && (
          <Button
            className="w-full py-2 rounded-8 text-xs"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              onSendForWork(car.id);
            }}
          >
            {t("game.footer.send-to-work")}
          </Button>
        )}

        {isWithProfit && (
          <Button
            className="w-full py-2 px-1 rounded-8 text-xs"
            variant="green"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              onCollectIncome(car.id);
            }}
          >
            {t("game.footer.collect")} +{car.available_txy_to_collect} {TXY}
          </Button>
        )}

        {isBusy && (
          <BusyTimerButton
            id={car.id}
            timerEndTime={car.ride_end_time ?? ""}
            onSuccess={onSuccessWork}
          />
        )}

        {isWorkedDuringLast24h && (
          <WaitForNextWorkButton
            timerEndTime={car.ride_start_time ?? ""}
            onSuccess={onSuccessCars}
          />
        )}
      </div>
    </li>
  );
}
