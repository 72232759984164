import { Link } from "../Link/Link";
import { MaskIcon } from "../ui/MaskIcon";
import { ROUTE, AppRoute } from "@/config/navigation";
import { useLocation } from "react-router-dom";
import { cn } from "@/utils";
import { useTranslation } from "react-i18next";
import { TranslateNamespaces } from "@/localization/localizationConfig";

export function AppNavigation() {
  const { t } = useTranslation("common");
  const location = useLocation();

  const locationFirstChunk = location.pathname.split("/")[1] as AppRoute;

  return (
    <>
      <nav className="fixed bottom-0 left-0 right-0 flex items-center bg-nav px-layout h-nav z-20">
        <ul className="flex w-full justify-between font-inter">
          {navList.map(({ path, translateTitleKey, icon, iconClassName }) => {
            const _isActive = locationFirstChunk === path;
            return (
              <li key={path}>
                <Link
                  className={cn(
                    "text-gray-middle font-medium text-xs flex flex-col items-center text-center",
                    {
                      "text-white-light": _isActive,
                    }
                  )}
                  to={path}
                >
                  <MaskIcon
                    iconSrc={icon}
                    iconSize="40%"
                    className={cn("block w-16 h-8 rounded-20 mb-1", {
                      "bg-black-dark ": _isActive,
                    })}
                    iconClassName={cn("bg-gray-middle", iconClassName, {
                      "bg-white-light": _isActive,
                    })}
                  />
                  {t(`nav.${translateTitleKey}`)}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="h-nav mt-layout" />
    </>
  );
}

import car from "./icons/car.svg";
import play from "./icons/play.svg";
import postsWrench from "./icons/posts-wrench.svg";
import store from "./icons/store.svg";
import strongBox from "./icons/strongbox.svg";

const navList: {
  path: AppRoute;
  translateTitleKey: keyof TranslateNamespaces["common"]["nav"];
  icon: string;
  iconClassName?: string;
}[] = [
  {
    path: ROUTE.PARK,
    translateTitleKey: "park",
    icon: car,
  },
  {
    path: ROUTE.SERVICE_STATION,
    translateTitleKey: "serviceStation",
    icon: postsWrench,
  },
  {
    path: ROUTE.MARKET,
    translateTitleKey: "market",
    icon: store,
  },
  {
    path: ROUTE.EXCHANGE,
    translateTitleKey: "exchange",
    icon: strongBox,
  },
  {
    path: ROUTE.PLAY,
    translateTitleKey: "play",
    icon: play,
    iconClassName: "bg-yellow-primary",
  },
];
