import background from "@/assets/tiers-sidebar.png";
import { Button } from "@/components/ui/Button";
import imgCompleted from "@/assets/repair-completed.png";
import { useTranslation } from "react-i18next";
// import { BreakDown } from "@/utils/types";
import { StatusCar } from "./ItemCarService";
import { BreakDown } from "@/api";
import { Lang } from "@/localization/localizationConfig";

export const SideBarContent = ({
  breakdown = {} as BreakDown,
  statusCar,
  onSubmit,
}: {
  breakdown: BreakDown | undefined;
  onSubmit: () => void;
  statusCar: StatusCar | undefined;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isCompleted = statusCar === "completed";

  const img = isCompleted
    ? imgCompleted
    : breakdown?.breakdown?.cover.file || imgCompleted;

  const repairCost = breakdown?.repair_cost || 0;

  const title = isCompleted
    ? t("carService.repair-completed")
    : breakdown.breakdown?.[`title_${language as Lang}`] ||
      t("carService.repair-completed");

  const description = isCompleted
    ? t("carService.car-returned")
    : breakdown.breakdown?.[`description_${language as Lang}`] ||
      t("carService.car-returned");

  const textButton = isCompleted
    ? t("carService.ok")
    : repairCost
    ? `${t("carService.pay")} ${repairCost} TXY`
    : t("carService.ok");

  return (
    <div className={"text-center"}>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
        }}
        className={"w-full h-44 bg-no-repeat flex items-center justify-center"}
      >
        <div
          style={{ backgroundImage: `url(${img})` }}
          className={
            "size-24 bg-no-repeat bg-contain bg-center bg-yellow-primary rounded-full"
          }
        ></div>
      </div>
      <div className="px-4">
        <h3 className={"font-montserrat text-lg font-black italic"}>{title}</h3>
        <p className={"text-sm"}> {description} </p>
        <Button
          onClick={onSubmit}
          variant="dark"
          className={"w-full mt-9 mb-6"}
        >
          {textButton}
        </Button>
      </div>
    </div>
  );
};
