import { api } from "@/api";
import { apiQueries } from "@/api/queries";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useQueryCarService = ({closeSideBar}: {closeSideBar: () => void}) => {

    const { data } = useQuery({...apiQueries.getCarsService, refetchInterval: 1000 * 60 * 3});

    const onSuccessRepair = () => {
        queryClient.invalidateQueries(apiQueries.getCarsService);
        queryClient.invalidateQueries(apiQueries.getProfile);
        closeSideBar();
    }

    const onSuccessCollect = () => {
        queryClient.invalidateQueries(apiQueries.getCarsService);
    }
    
    const queryClient = useQueryClient()

    const mutationRepair = useMutation({mutationFn: api.repairCar, onSuccess: onSuccessRepair});
    const mutationCollect = useMutation({mutationFn: api.collectCar, onSuccess: onSuccessCollect});
  
    const repair = async (id: number) => {
      return mutationRepair.mutate(id);
    }

    const collect = async (id: number) => {
        mutationCollect.mutate(id);
    }
    return {repair, collect, data};
}