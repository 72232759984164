import { apiQueries } from "@/api/queries";
import { useQuery } from "@tanstack/react-query";

import carImgForEmptyList from "./car.svg";

import { EmptyListInformer } from "@/components/ui/EmptyListInformer";
import { CardParkCar } from "@/components/cards/CardParkCar/CardParkCar";
import { useTranslation } from "react-i18next";
import { Link } from "@/components/Link/Link";
import { ROUTE, generateDynamicRoute } from "@/config/navigation";

export function ParkPage() {
  const { t } = useTranslation("common");
  const { data: carsData } = useQuery(apiQueries.getUserParkCars);

  return (
    <div className="flex-1 flex flex-col">
      {carsData?.results.length === 0 && (
        <EmptyListInformer
          img={carImgForEmptyList}
          text={t("park.emptyParkText")}
        />
      )}

      <ul className="flex flex-col gap-2">
        {carsData?.results.map(
          ({
            id,
            title,
            cover,
            reliability,
            annual_txy_profit,
            annual_usdt_profit,
          }) => (
            <Link key={id} to={generateDynamicRoute(ROUTE.CAR_INFO, id)}>
              <CardParkCar
                name={title}
                profitTXY={annual_txy_profit}
                profitUSDT={annual_usdt_profit}
                reliability={reliability}
                image={cover.file}
              />
            </Link>
          )
        )}
      </ul>
    </div>
  );
}
