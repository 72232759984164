import { appFetch } from "./appFetch";

export const api = {
  // deprecated

  // getUserParkCars(
  //   params?: PaginatorParams &
  //     FindParam &
  //     OrderingParam<
  //       | "id"
  //       | "car__discount_price"
  //       | "car__daily_txy_profit"
  //       | "car__reliability"
  //     >
  // ) {
  //   return appFetch<ResponseGetUserCars>("users/fleets/cars", undefined, {
  //     queryParams: params,
  //   });
  // },

  getUserParkCars(
    params?: PaginatorParams &
      FindParam &
      OrderingParam<
        | "id"
        | "car__discount_price"
        | "car__daily_txy_profit"
        | "car__reliability"
      >
  ) {
    return appFetch<ResponseGetUserCars>("users/fleet", undefined, {
      queryParams: params,
    });
  },

  getMarketCars(params?: ParamsGetMarketCars) {
    return appFetch<ResponseGetMarketCars>("users/markets/cars", undefined, {
      queryParams: params,
    });
  },

  // deprecated

  // getParkCarInfo(id: number) {
  //   return appFetch<ResponseGetParkCarInfo>(`users/fleets/cars/${id}`);
  // },

  getParkCarInfo(id: number) {
    return appFetch<ResponseGetParkCarInfo>(`users/fleet/${id}`);
  },

  getParkCarsCollection(
    id: number,
    params: PaginatorParams & CarsCollectionParam
  ) {
    return appFetch<WithPaginator<ResponseGetParkCarsCollection>>(
      `users/fleet/${id}/cars`,
      undefined,
      {
        queryParams: params,
      }
    );
  },

  getMarketCarInfo(id: number) {
    return appFetch<ResponseGetMarketCarInfo>(`users/markets/cars/${id}`);
  },

  getUserTasks() {
    return appFetch<ResponseGetUserTasks>("users/tasks");
  },

  getUserProfile() {
    return appFetch<ResponseGetUserProfile>("users/me");
  },

  getCarsService() {
    return appFetch<ResponseGetCarsService>("users/repair-shops/cars");
  },
  repairCar(id: number) {
    return appFetch<CarServiceItem>(`users/repair-shops/cars/${id}/repair`, {
      method: "POST",
    });
  },
  collectCar(id: number) {
    return appFetch<CarServiceItem>(`users/repair-shops/cars/${id}/collect`, {
      method: "POST",
    });
  },
  getGameCars(params?: PaginatorParams & FindParam) {
    //&
    // OrderingParam<"id" | "price" | "daily_profit" | "reliability">) {
    return appFetch<ResponseGetGameCars>("users/games/cars", undefined, {
      queryParams: params,
    });
  },

  collectIncomeFromGar(id: number) {
    return appFetch<ResponseGetGameCars>(`users/games/cars/${id}/collect`, {
      method: "POST",
    });
  },

  sendCarForWork(id: number) {
    return appFetch<ResponseGetGameCars>(`users/games/cars/${id}/send`, {
      method: "POST",
    });
  },

  transferToBalance() {
    return appFetch<ResponseGetGameCars>(`users/games/cars/earnings/transfer`, {
      method: "POST",
    });
  },

  getGameEarnings() {
    return appFetch<{
      earnings: string;
    }>("users/games/earnings");
  },
  getExchanges(params: PaginatorParams & GetExchangesSpecialParams) {
    return appFetch<ResponseGetExchanges>("users/exchanges", undefined, {
      queryParams: params,
    });
  },
  getCurrentRate() {
    return appFetch<ResponseGetCurrentRate>("users/exchanges/current");
  },
  requestWithdrawal(data: RequestWithdrawal) {
    return appFetch(`users/requests`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  },

  getTransactions(params: PaginatorParams & GetTransactionsSpecialParams) {
    return appFetch<WithPaginator<ResponseGetTransactions>>(
      `users/transactions`,
      undefined,
      {
        queryParams: params,
      }
    );
  },

  // deprecated
  addSticker(id: number) {
    return appFetch<undefined>(`users/fleets/cars/${id}/sticker`, {
      method: "POST",
    });
  },

  purchaseCar(id: number) {
    return appFetch<undefined>(`users/markets/cars/${id}/purchase`, {
      method: "POST",
    });
  },
  getReferralInfo() {
    return appFetch<ReferralInfo>("users/referrals");
  },
  getAvatars() {
    return appFetch<WithPaginator<Image>>("users/avatars", undefined, {
      queryParams: {
        page: 1,
        size: 20,
      },
    });
  },
  changeAvatar(id: string) {
    return appFetch<undefined>(`users/me`, {
      method: "PATCH",
      body: JSON.stringify({ avatar: id }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
  },
  getReferralsStatuses() {
    return appFetch<ReferralsStatus[]>("users/referrals/statuses");
  },
};

export type ApiError = {
  type: string;
  errors: {
    code: string;
    detail: string;
    attr: null;
  }[];
};

type WithPaginator<T> = {
  results: T[] | [];
  count: number;
  next: string;
  previous: string;
};

type PaginatorParams = {
  page?: number;
  size?: number;
};

type OrderingParam<T extends string> = {
  ordering?: T | `-${T}`;
};

type FindParam = {
  search?: string;
};

type Image = {
  id: string;
  file: string;
};

export type ResponseGetMarketCars = WithPaginator<{
  //{
  //   "id": 0,
  //   "title": "string",
  //   "cover": {
  //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "file": "string"
  //   },
  //   "full_price": "-9385.0",
  //   "discount_price": "-1048.97",
  //   "semiannual_txy_profit": "136464",
  //   "semiannual_usdt_profit": "-1519079.37",
  //   "reliability": 0,
  //   "is_purchased": true,
  //   "purchased_car_id": 0,
  //   "is_locked": true,
  //   "tasks_to_unlock": [
  //     {
  //       "id": 0,
  //       "description_ru": "string",
  //       "description_ua": "string"
  //     }
  //   ]
  // }
  id: number;
  title: string;
  cover: Image;
  full_price: string;
  discount_price: string;
  semiannual_txy_profit: string;
  semiannual_usdt_profit: string;
  annual_txy_profit: string;
  annual_usdt_profit: string;
  daily_txy_profit: string;
  daily_usdt_profit: string;
  monthly_txy_profit: string;
  monthly_usdt_profit: string;
  reliability: number;
  is_purchased: boolean;
  purchased_car_id: number;
  is_locked: boolean;
  tasks_to_unlock: Pick<
    Task["task"],
    "id" | "description_ru" | "description_ua" | "description_en"
  >[];
  current_count_per_user: number;
  max_count_per_user: number;
}>;

export type ParamsGetMarketCars = PaginatorParams &
  FindParam &
  OrderingParam<
    "id" | "discount_price" | "reliability" | "daily_txy_profit"
  > & {
    exclude?: number;
    only_non_purchased?: boolean;
  };

type ResponseGetUserCars = WithPaginator<{
  //{
  //   "id": 0,
  //   "car": {
  //     "id": 0,
  //     "title": "string",
  //     "semiannual_txy_profit": "122.",
  //     "semiannual_usdt_profit": ".",
  //     "reliability": 0
  //   },
  //   "cover": {
  //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  //     "file": "string"
  //   },
  //   "is_stickered": true,
  //   "is_broken": false
  // }

  id: number;
  title: "string";
  cover: Image;
  reliability: number;
  annual_txy_profit: string;
  annual_usdt_profit: string;
  // car: {
  //   id: number;
  //   title: string;
  //   semiannual_txy_profit: string;
  //   semiannual_usdt_profit: string;
  //   annual_txy_profit: string;
  //   annual_usdt_profit: string;
  //   reliability: number;
  // };
  // cover: Image;
  // is_stickered: boolean;
  // is_broken: boolean;
}>;

type Car = {
  annual_txy_profit: string;
  id: number;
  title: string;
  description_ru: string;
  description_ua: string;
  description_en: string;
  cover: Image;
  discount_price: number;
  daily_txy_profit: string;
  daily_usdt_profit: string;
  monthly_txy_profit: string;
  monthly_usdt_profit: string;
  semiannual_txy_profit: string;
  semiannual_usdt_profit: string;
  reliability: number;
};

export type ResponseGetMarketCarInfo = Car;

export type ResponseGetParkCarInfo = {
  // id: number;
  // car: {
  //   id: number;
  //   title: string;
  //   description_ru: string;
  //   description_ua: string;
  //   description_en: string;
  //   daily_txy_profit: string;
  //   daily_usdt_profit: string;
  //   monthly_txy_profit: string;
  //   monthly_usdt_profit: string;
  //   semiannual_txy_profit: string;
  //   semiannual_usdt_profit: string;
  //   annual_txy_profit: string;
  //   annual_usdt_profit: string;
  //   reliability: number;
  //   default_cover: Image;
  //   cover_with_sticker: Image;
  // };
  // // cover: Image;
  // is_stickered: boolean;
  // sticker_cost: string;
  // purchased_for: string;
  id: number;
  title: string;
  cover: Image;
  cover_with_sticker: Image | null;
  description_ru: string;
  description_ua: string;
  description_en: string;
  daily_txy_profit: string;
  daily_usdt_profit: string;
  monthly_txy_profit: string;
  monthly_usdt_profit: string;
  annual_txy_profit: string;
  annual_usdt_profit: string;
  reliability: number;
  current_count_per_user: number;
  max_count_per_user: number;
};

type CarsCollectionParam = {
  ordering?: string;
  search?: string;
};

export type ResponseGetParkCarsCollection = {
  id: number;
  car: {
    id: number;
    title: string;
  };
  sequence_id: number;
  cover: Image;
  is_broken: boolean;
  is_stickered: boolean;
};

export type Task = {
  id: number;
  task: {
    id: number;
    title_ru: string;
    title_ua: string;
    title_en: string;
    description_ru: string;
    description_ua: string;
    description_en: string;
    cover: {
      id: string;
      file: string;
    };
    max_progress: number;
  };
  current_progress: number;
  is_performed: boolean;
};

type ResponseGetUserProfile = {
  id: number;
  username: string;
  balance: string;
  earnings: string;
  avatar: Image;
};

type ResponseGetCarsService = WithPaginator<CarServiceItem>;

type ResponseGetUserTasks = Task[];

export type GameCar = {
  id: number;
  sequence_id: number;
  car: {
    id: number;
    title: string;
  };
  cover: Image;
  ride_end_time: string | null;
  available_txy_to_collect: string | null;
  is_broken: boolean;
  ride_start_time: string | null;
};
type ResponseGetGameCars = WithPaginator<GameCar>;

type ResponseGetExchanges = WithPaginator<{
  date: string;
  rate: number;
}>;

type ResponseGetCurrentRate = {
  id: number;
  updated_at: string;
  usdt_per_txy: string;
};

export type ExchangesInterval = "day" | "week" | "month" | "year" | "all";

type GetExchangesSpecialParams = {
  interval: ExchangesInterval;
};

// export type Cover = {
//   id: string;
//   file: string;
// };

export type BreakDownInfo = {
  id: number;
  title_ru: string;
  title_ua: string;
  title_en: string;
  description_ru: string;
  description_ua: string;
  description_en: string;
  cover: Image;
};
export type BreakDown = {
  id: number;
  breakdown: BreakDownInfo;
  repair_cost: string;
  repaired_at: Date | null;
};
export type CarService = {
  id: 0;
  title: string;
};
export type CarServiceItem = {
  id: number;
  car: CarService;
  cover: Image;
  last_breakdown: BreakDown;
  sequence_id: number;
};

type RequestWithdrawal = {
  requested_amount: string;
  wallet: string;
};

export type ReferralNetworkItem = {
  id: number;
  name: string;
  total_referral_usdt_earnings: string;
};

export type ReferralInfo = {
  id: number;
  referral_program_status: {
    id: number;
    title_ru: string;
    title_ua: string;
    title_en: string;
  };
  referrals_profit_amount: number;
  referral_count: number;
  referrals: ReferralNetworkItem[];
  referral_link: string;
};

export type ReferralsStatus = {
  id: number;
  title_ru: string;
  title_ua: string;
  title_en: string;
  min_referral_count: number;
  max_referral_count: number;
  income_per_referral_in_percent: number;
  cover: Image;
};

export type PaymentType = "deposit" | "withdrawal";

type GetTransactionsSpecialParams = {
  payment_type: PaymentType;
  ordering:
    | "id"
    | "create_date"
    | "-create_date"
    | "withdrawal_amount"
    | "transfer_amount";
};

export type TransactionStatus =
  | "Waiting"
  | "Successful"
  | "Rejected"
  | "Processing"
  | "Unpaid"
  | "Cancelled";

export type ResponseGetTransactions = {
  id: number;
  create_date: string;
  status_name: TransactionStatus;
  payment_type: PaymentType;
  withdrawal_amount: string;
  transfer_amount: string;
};
