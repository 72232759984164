import { getSavedLang } from "@/utils";
import { ApiError } from ".";
import { getSavedToken } from "./apiUtils";

const API_ORIGIN = import.meta.env.VITE_API_DOMAIN;
const API_VER_CHUNKS = `/api/v${import.meta.env.VITE_API_VERSION}/`;

export async function appFetch<T>(
  url: string,
  fetchOptions: RequestInit = {},
  options?: {
    withoutAuth?: boolean;
    queryParams?: Record<string, string | number | boolean>;
  }
) {
  const fullPath = new URL(url, API_ORIGIN + API_VER_CHUNKS);

  if (options?.queryParams) {
    // @ts-expect-error URLSearchParams gets numbers and bool too
    fullPath.search = new URLSearchParams(options.queryParams).toString();
  }

  if (!fetchOptions?.headers) {
    fetchOptions.headers = {};
  }

  (fetchOptions.headers as Record<string, string>)["accept-language"] =
    getSavedLang();

  if (options?.withoutAuth !== false) {
    (
      fetchOptions.headers as Record<string, string>
    ).Authorization = `X-Tg-Token ${getSavedToken()}`;
  }

  const response = await fetch(fullPath, fetchOptions);

  if (!response.ok) {
    if (response.status === 400)
      throw (await response.json()) as ApiError;
    throw await response.json();
  }

  return await (response.json() as Promise<T>);
}
