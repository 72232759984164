import { Card } from "@/components/cards/Card/Card";
import { Button } from "@/components/ui/Button";
import { cn } from "@/utils";
import { imgWrenches } from "@/assets";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

export function CollectionCard(p: {
  id: number;
  title: string;
  cover: string;
  isBroken: boolean;
  isStickered: boolean;
  sequenceId: number;
  setStickerOpenId: Dispatch<SetStateAction<number | null>>;
}) {
  const { t } = useTranslation("common");

  return (
    <Card className="flex gap-2.5">
      <div
        className={cn(
          "bg-gray-dark-image rounded-8 my-2 py-2 w-2/5 items-center justify-center flex relative",
          { "backdrop-blur-md relative": p.isBroken }
        )}
      >
        {!p.isBroken && <img src={p.cover} />}
        {p.isBroken && (
          <div
            className="size-14 bg-yellow-primary rounded-full bg-center bg-no-repeat bg-contain shrink-0 absolute"
            style={{
              backgroundImage: `url(${imgWrenches})`,
              backgroundSize: "85%",
            }}
          />
        )}
      </div>

      <div className="my-2 w-3/5">
        <div className="text-base text-white-light font-bold">
          {p.sequenceId}. {p.title}
        </div>

        {p.isBroken && (
          <div className="text-xs">{t("cardBlockers.needRepair.body")}</div>
        )}
        {!p.isBroken && (
          <Button
            className="rounded-full py-2 px-1 mt-3 text-xs w-full"
            disabled={p.isStickered}
            onClick={() => p.setStickerOpenId(p.id)}
          >
            {t("actions.increaseIncome")}
          </Button>
        )}
      </div>
    </Card>
  );
}
