import { useTranslation } from "react-i18next";
import { ListItem } from "./ListItem";
import { ResponseGetTransactions } from "@/api";
import { useEffect, useState } from "react";

type RegroupedData = {
  create_date: string;
  transactions: ResponseGetTransactions[];
}[];

export function List({ data }: { data: ResponseGetTransactions[] }) {
  const {
    i18n: { language },
  } = useTranslation("common");

  const [regroupedData, setRegroupedData] = useState<RegroupedData>([]);

  useEffect(() => {
    if (data) {
      const newData: RegroupedData = Object.entries(
        data.reduce(
          (acc: { [key: string]: ResponseGetTransactions[] }, item) => {
            const date = item.create_date.split("T")[0];
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          },
          {}
        )
      ).map(([key, value]) => ({ create_date: key, transactions: value }));

      setRegroupedData(newData);
    }
  }, [data]);

  return (
    <ul className="mt-4">
      {regroupedData.map((item) => (
        <li key={item.create_date} className="mb-6">
          <span className="text-white-65 text-sm font-bold">
            {new Date(item.create_date).toLocaleDateString(language, {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </span>
          <ul className="mt-1.5 flex flex-col gap-2">
            {item.transactions.map((_item) => (
              <ListItem data={_item} key={_item.id} />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}
