import { apiMutations, apiQueries, QueryKey } from "@/api/queries";
import { useAppParams } from "@/navigation/useAppParams";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { HeaderScreen } from "../profile/HeaderScreen";
import { CarInfo } from "@/components/car/CarInfo";
import { Button } from "@/components/ui/Button";
import { TXY, USDT } from "@/variables";
import { FixedBottomContainer } from "@/components/layouts/FixedBottomContainer";
import { ResponseGetMarketCars } from "@/api";
import { Card } from "@/components/cards/Card/Card";
import { Head } from "@/components/ui/Head";
import { Link } from "@/components/Link/Link";
import { generateDynamicRoute, ROUTE } from "@/config/navigation";
import { useNavigate } from "react-router-dom";
import { useInvalidateQueriesByKeys } from "@/api/apiUtils";

export function MarketCarInfoPage() {
  const { carId } = useAppParams();
  const navigate = useNavigate();
  const { data: carInfo, isSuccess } = useQuery(
    apiQueries.getMarketCarInfo(+carId!)
  );

  const { data: alsoBuyCarsData, isSuccess: isAlsoBuySuccess } = useQuery(
    apiQueries.getMarketCars({
      exclude: +carId!,
      only_non_purchased: true,
      size: 3,
      ordering: "-discount_price",
    })
  );

  const { userProfile } = useInvalidateQueriesByKeys([QueryKey.userProfile]);

  const { mutateAsync: purchaseCar, isPending: isPendingPurchase } =
    useMutation(apiMutations.purchaseCar);

  async function purchaseCarHandler() {
    await purchaseCar(+carId!);
    userProfile.invalidate();
    // todo: после покупки перекидываю в Рынок. Если купить еще доп машинки возможно, то не перекидываем, когда купили последнюю доступную - перекидываем ??
    navigate(ROUTE.MARKET);
  }

  const { t } = useTranslation("common");

  if (!isSuccess) {
    return null;
  }

  return (
    <div>
      <HeaderScreen title={carInfo.title} isBack />
      <CarInfo
        price={carInfo.discount_price}
        cover={carInfo.cover.file}
        profits={{
          perDay: {
            txy: carInfo.daily_txy_profit,
            usdt: carInfo.daily_usdt_profit,
          },
          perMonth: {
            txy: carInfo.monthly_txy_profit,
            usdt: carInfo.monthly_usdt_profit,
          },
          perYear: {
            txy: carInfo.annual_txy_profit,
            usdt: carInfo.annual_txy_profit,
          },
        }}
        reliability={carInfo.reliability}
        descriptionRu={carInfo.description_ru}
        descriptionUa={carInfo.description_ua}
        descriptionEn={carInfo.description_en}
      />

      {isAlsoBuySuccess && alsoBuyCarsData.count && (
        <>
          <Head className="mt-6 mb-1 text-base">{t("market.alsoBuy")}</Head>

          <AlsoBuyCarsList list={alsoBuyCarsData.results} />
        </>
      )}

      <FixedBottomContainer isNavVisible>
        <Button
          disabled={isPendingPurchase}
          onClick={purchaseCarHandler}
          className="w-full"
        >
          {+carInfo.discount_price === 0
            ? t("actions.getForFree")
            : `${t("actions.pay")} ${carInfo.discount_price} ${TXY}`}
        </Button>

        {/* todo: если куплено максимальное количество авто (15/15), то кнопку "купить" не показывать */}
      </FixedBottomContainer>
    </div>
  );
}

function AlsoBuyCarsList(p: { list: ResponseGetMarketCars["results"] }) {
  const { t } = useTranslation("common");
  const [translatedProfit, translatedReliability, translatedAction] = [
    t("car.profit"),
    t("car.reliability"),
    t("actions.buyFor"),
  ];

  return (
    <ul className="space-y-2">
      {p.list?.map((car) => (
        <AlsoBuyCarCard
          as="li"
          car={car}
          key={car.id}
          translatedProfit={translatedProfit}
          translatedReliability={translatedReliability}
          translatedAction={translatedAction}
        />
      ))}
    </ul>
  );
}

function AlsoBuyCarCard(p: {
  car: ResponseGetMarketCars["results"][number];
  translatedProfit: string;
  translatedReliability: string;
  translatedAction: string;
  as?: keyof JSX.IntrinsicElements;
}) {
  const Elem = p.as || "div";
  return (
    <Elem className=" bg-black-dark-card rounded-10 p-3 border border-card-border">
      <Link to={generateDynamicRoute(ROUTE.MARKET_CAR_INFO, p.car.id)}>
        <h2 className="font-bold mb-2 text-md flex items-center justify-between">
          {p.car.title}

          <svg
            width="24"
            className="size-6"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3972 19.2588L10.8254 17.7006L14.9513 13.5747H5.28355V11.2847H14.9513L10.8254 7.16562L12.3972 5.60062L19.2263 12.4297L12.3972 19.2588Z"
              fill="#FEFEFE"
            />
          </svg>
        </h2>

        <div className="flex gap-2">
          <img
            className="w-3/5 object-contain object-center bg-black-footer rounded-6 px-1 py-2"
            src={p.car.cover.file}
            alt={p.car.title}
          />
          <div className="flex-1 space-y-2 font-medium text-xs whitespace-pre-wrap">
            <Card variant="mini" className="bg-black-footer">
              <div className="text-gray-light">{p.translatedProfit}</div>
              {`до ${p.car.annual_txy_profit} ${TXY}\n(${p.car.annual_usdt_profit} ${USDT})`}
            </Card>
            <Card variant="mini" className="bg-black-footer">
              <div className="text-gray-light">{p.translatedReliability}</div>
              {p.car.reliability}%
            </Card>
          </div>
        </div>

        <Button variant="dark" className="w-full rounded-6 mt-3">
          {p.translatedAction} {p.car.discount_price} {TXY}
        </Button>
      </Link>
    </Elem>
  );
}
