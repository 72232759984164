"use client";

import { createContext, useEffect, useState } from "react";
// import { useRef } from 'react'
import { Unity, useUnityContext } from "react-unity-webgl";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";
import { Spinner } from "@/components/ui/Spinner";

type SetCarIntervals = ({
  id,
  interval,
}: {
  id: number;
  interval: number;
}) => void;

type SendMapMessage = <T extends CarsMapMethods>(
  methodName: T,
  parameters: Parameters<SendMessage[T]>[0]
) => void;

export const GameContext = createContext({
  setCarIntervals: (() => {}) as SetCarIntervals,
  sendMapMessage: (() => {}) as SendMapMessage,
});

export function GamePage() {
  const {
    unityProvider,
    sendMessage: libSendMessage,
    isLoaded,
    unload,
  } = useUnityContext({
    loaderUrl: "./unity-assets/CarsSimulation5.loader.js",
    dataUrl: "./unity-assets/CarsSimulation5.data",
    frameworkUrl: "./unity-assets/CarsSimulation5.framework.js",
    codeUrl: "./unity-assets/CarsSimulation5.wasm",
  });

  const [delCarIntervals, setDelCarIntervals] = useState<
    { id: number; interval: number }[]
  >([]);

  const setCarIntervals = ({
    id,
    interval,
  }: {
    id: number;
    interval: number;
  }) => {
    if (!delCarIntervals.some((el) => el.id === id)) {
      setDelCarIntervals((prevIntervals) => [
        ...prevIntervals,
        { id, interval },
      ]);
    } else {
      setDelCarIntervals((prevIntervals) =>
        prevIntervals.filter((el) => el.id === id)
      );
    }
  };
  //   function sendMessage(method: CarsMapMethods, arg: ) {
  //     libSendMessage('Bridge', method, JSON.stringify(args))
  //   }

  const { data: userCars } = useQuery({
    ...apiQueries.getGameCars({
      page: 1,
      size: 100,
    }),
    // refetchInterval: 1000 * 60 * 1, // запрашиваем все машины каждую минуту
  });

  function sendMessage<T extends CarsMapMethods>(
    methodName: T,
    parameters: Parameters<SendMessage[T]>[0]
  ) {
    libSendMessage("Bridge", methodName, JSON.stringify(parameters));
  }

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (userCars) {
      if (
        userCars?.count === 0 ||
        userCars.results.filter((car) => car.ride_end_time !== null).length ===
          0
      ) {
        sendMessage("RemoveAllCars", "empty");
      } else {
        sendMessage("CreateCars", {
          ids: userCars.results
            .filter((car) => car.ride_end_time !== null)
            .map((car) => car.id),
        });
      }
    }
  }, [isLoaded, userCars]);

  useEffect(() => {
    const intervals: NodeJS.Timeout[] = [];
    delCarIntervals.forEach(({ interval, id }) => {
      const intervalId = setInterval(() => {
        sendMessage("RemoveCar", id);
      }, interval * 1000);

      intervals.push(intervalId);
    });

    return () => {
      intervals.forEach((interval) => clearInterval(interval));
    };
  }, [delCarIntervals]);

  //document.addEventListener('wheel', onScroll, false);
  // document.addEventListener('mousemove', onMouse, false);
  // var content = document.getElementsByClassName('webgl-content');
  // function onMouse() { content[0].style['pointer-events'] = 'auto'; }
  // function onScroll() { content[0].style['pointer-events'] = 'none'; }
  //   const containerRef = useRef<HTMLDivElement>(null)

  //   useEffect(() => {
  //   document.addEventListener('wheel', onScroll, false)
  //   document.addEventListener('mousemove', onMouse, false)
  //   //   var content = document.getElementsByClassName('webgl-content');
  //   function onMouse() {
  //     //   console.log("🚀 ~ onMouse ~ onMouse:", onMouse)
  //     containerRef.current!.style.pointerEvents = 'auto'
  //   }
  //   function onScroll() {
  //     //   console.log("🚀 ~ onScroll ~ onScroll:", onScroll)
  //     containerRef.current!.style.pointerEvents = 'none'
  //   }

  //     window.addEventListener(
  //       'wheel',
  //       event => {
  //         event.preventDefault()
  //       },
  //       { passive: false },
  //     )
  //   }, [])

  // const [scale, setScale] = useState(1);
  // const [initialDistance, setInitialDistance] = useState(null);

  // const handleWheel = (event) => {
  //   event.preventDefault();
  //   const newScale = scale + event.deltaY * -0.01;
  //   setScale(Math.min(Math.max(0.5, newScale), 2)); // Ограничение зума от 0.5 до 2
  // };

  // const handleTouchStart = (event) => {
  //   if (event.touches.length === 2) {
  //     setInitialDistance(getDistance(event.touches));
  //   }
  // };

  // const handleTouchMove = (event) => {
  //   if (event.touches.length === 2 && initialDistance !== null) {
  //     const currentDistance = getDistance(event.touches);
  //     const newScale = scale * (currentDistance / initialDistance);
  //     setScale(Math.min(Math.max(0.5, newScale), 2)); // Ограничение зума от 0.5 до 2
  //     setInitialDistance(currentDistance);
  //   }
  // };

  // const handleTouchEnd = () => {
  //   setInitialDistance(null);
  // };

  // const getDistance = (touches) => {
  //   const dx = touches[0].clientX - touches[1].clientX;
  //   const dy = touches[0].clientY - touches[1].clientY;
  //   return Math.sqrt(dx * dx + dy * dy);
  // };

  // useEffect(() => {
  //   window.addEventListener("wheel", handleWheel);
  //   window.addEventListener("touchstart", handleTouchStart);
  //   window.addEventListener("touchmove", handleTouchMove);
  //   window.addEventListener("touchend", handleTouchEnd);

  //   return () => {
  //     window.removeEventListener("wheel", handleWheel);
  //     window.removeEventListener("touchstart", handleTouchStart);
  //     window.removeEventListener("touchmove", handleTouchMove);
  //     window.removeEventListener("touchend", handleTouchEnd);
  //   };
  // }, [scale, initialDistance]);

  return (
    <section>
      <Header unload={unload} />
      {/* <div
        onWheel={handleWheel}
        className="h-screen w-screen -mx-layout overflow-hidden relative"
      > */}
      {/* <div
          style={{
            // transform: `scale(${scale})`,
            transformOrigin: "top left", // Устанавливаем точку масштабирования
            width: "100vw", // Занимает всю ширину родителя
            height: "100vh", // Занимает всю высоту родителя
          }}
        > */}
      <Unity
        className="h-screen w-screen -mx-layout"
        // style={{
        //   transform: `scale(${scale})`,
        // }}
        devicePixelRatio={2}
        unityProvider={unityProvider}
      />
      {/* </div> */}
      {/* </div> */}
      {!isLoaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <GameContext.Provider
        value={{ setCarIntervals, sendMapMessage: sendMessage }}
      >
        <Footer />
      </GameContext.Provider>
      {/* <div className='flex gap-4'>
            <Button type='button' onClick={() => sendMessage('AddCar', 1)}>
              Add Car
            </Button>
            <Button type='button' onClick={() => sendMessage('CreateCars', { ids: [2, 3, 4] })}>
              Add Cars
            </Button>
            <Button type='button' onClick={() => sendMessage('RemoveAllCars', 'empty')}>
              Remove All Cars
            </Button>
            <Button type='button' onClick={() => sendMessage('RemoveCar', 1)}>
              Remove Car
            </Button>
          </div> */}
    </section>
  );
}

export type CarsMapMethods =
  | "CreateCars"
  | "AddCar"
  | "RemoveAllCars"
  | "RemoveCar";

export type SendMessage = {
  CreateCars: (data: { ids: number[] }) => void;
  AddCar: (carId: number) => void;
  RemoveAllCars: (empty: "empty") => void;
  RemoveCar: (carId: number) => void;
};
