import { useTranslation } from "react-i18next";
import noDataIcon from "@/assets/car-service.png";
import { ItemCarService, StatusCar } from "./ItemCarService";
import { SideBar } from "@/components/sideBar/SideBar";
import { useEffect, useState } from "react";
import { SideBarContent } from "./SideBarContent";
import { useQueryCarService } from "./useQueryCarService";

export type SelectedCar = {
  id: number | null;
  status: StatusCar;
};

export const CarService = () => {
  const { t } = useTranslation();

  const [selectedCar, setSelectedCar] = useState<SelectedCar | null>(null);

  const [isOpenSideBar, setIsOpenSideBar] = useState(false);

  useEffect(() => {
    if (selectedCar) {
      setIsOpenSideBar(true);
    }
  }, [selectedCar]);

  const closeSideBar = () => {
    setIsOpenSideBar(false);
    setTimeout(() => {
      setSelectedCar(null);
    }, 500);
  };

  const { data, repair, collect } = useQueryCarService({ closeSideBar });

  const selectCarItem = data?.results.find(
    (item) => item.id === selectedCar?.id
  )?.last_breakdown;

  const onSubmit = async () => {
    if (!selectedCar) {
      return;
    }
    if (selectedCar.status === "break") {
      await repair(selectedCar.id!);
    }
    if (selectedCar.status === "completed") {
      await collect(selectedCar.id!);
    }
    return closeSideBar();
  };
  if (!data || data.results.length === 0) {
    return (
      <div className={"my-auto"}>
        <div
          style={{ backgroundImage: `url(${noDataIcon})` }}
          className={
            "size-24 bg-no-repeat bg-contain bg-center self-center mb-5 mx-auto"
          }
        ></div>
        <p className={"text-gray-middle text-center"}>
          {t("carService.empty")}
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        {data.results.map((item) => (
          <ItemCarService
            idItem={item.id}
            key={item.id}
            title={item.car.title}
            sequenceId={item.sequence_id}
            cover={item.cover.file}
            breakdown={item.last_breakdown}
            setSelectedCar={setSelectedCar}
          />
        ))}
      </div>
      <SideBar withImage isOpen={isOpenSideBar} setIsOpen={closeSideBar}>
        <SideBarContent
          onSubmit={onSubmit}
          statusCar={selectedCar?.status}
          breakdown={selectCarItem}
        />
      </SideBar>
    </>
  );
};
