import { apiMutations, apiQueries, QueryKey } from "@/api/queries";
import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ParkCard } from "./ParkCard";
import { TXY } from "@/variables";
import { useTranslation } from "react-i18next";
import { invalidateByPredicate } from "@/api/apiUtils";
import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { GameContext } from "./GamePage";

export function ParkSidebar({
  isParkOpen,
  setIsParkOpen,
}: {
  isParkOpen: boolean;
  setIsParkOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation("common");
  const queryClient = useQueryClient();

  const { sendMapMessage, setCarIntervals } = useContext(GameContext);

  const [isTransferOpen, setIsTransferOpen] = useState(false);

  const { data: userCars } = useQuery({
    ...apiQueries.getGameCars({
      page: 1,
      size: 100,
    }),
    refetchOnMount: true,
    enabled: isParkOpen,
  });
  const { data: userEarnings } = useQuery(apiQueries.getGameEarnings());

  useEffect(() => {
    if (
      userCars?.count === 0 ||
      userCars?.results.filter((car) => car.ride_end_time !== null).length === 0
    ) {
      sendMapMessage("RemoveAllCars", "empty");
    } else if (userCars?.results.some((car) => car.ride_end_time !== null)) {
      sendMapMessage("CreateCars", {
        ids: userCars?.results
          .filter((car) => car.ride_end_time !== null)
          .map((car) => car.id),
      });
    }
  }, [userCars]);

  const onSuccessCars = () => {
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.gameCars]),
    });
  };

  const onSuccessTransfer = () => {
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.gameEarnings]),
    });
    queryClient.invalidateQueries({
      predicate: invalidateByPredicate([QueryKey.userProfile]),
    });
  };

  const collectIncome = useMutation({
    ...apiMutations.collectIncomeFromGar,
    onSuccess: (_, id) => {
      onSuccessTransfer();
      onSuccessCars();
      setCarIntervals({
        id,
        interval: 0,
      });
    },
  });

  const sendForWork = useMutation({
    ...apiMutations.sendCarForWork,
    onSuccess: (_, id) => {
      sendMapMessage("AddCar", id);
      onSuccessCars();
    },
  });

  const transferToBalance = useMutation({
    ...apiMutations.transferToBalance,
    onSuccess: () => {
      onSuccessTransfer();
      onSuccessCars();
      setIsTransferOpen(false);
    },
  });

  const onCollectIncome = (id: number) => {
    if (id) {
      collectIncome.mutateAsync(id);
    }
  };

  const onSendForWork = (id: number) => {
    if (id) {
      sendForWork.mutateAsync(id);
    }
  };

  const onTransferToBalance = () => {
    transferToBalance.mutateAsync();
  };

  const onSuccessWork = (id: number) => {
    onSuccessCars();
    sendMapMessage("RemoveCar", id);
  };

  return (
    <>
      <SideBar isOpen={isParkOpen && !isTransferOpen} setIsOpen={setIsParkOpen}>
        <h2
          className={
            "font-montserrat font-extrabold text-lg italic text-center mt-5 mb-5"
          }
        >
          {t("game.footer.park")}
        </h2>

        <div className="bg-gray-light-01 py-2 pl-3 pr-2 rounded-10 font-inter text-[0.8rem] mb-3 flex items-center gap-2">
          <div className="flex gap-1 flex-wrap">
            <span className="font-black">{t("game.footer.my-income")}:</span>{" "}
            <span className="font-bold">
              {userEarnings?.earnings ?? 0} {TXY}
            </span>
          </div>
          <Button
            onClick={() => {
              setIsTransferOpen(true);
            }}
            className="ml-auto text-white-light p-2 text-xs rounded-8 text-nowrap"
            variant="dark"
          >
            {t("game.footer.transfer-to-balance")}
          </Button>
        </div>

        <ul className="grid grid-cols-2 gap-2 max-h-[500px] overflow-y-auto pb-4">
          {userCars?.results.map((car) => (
            <ParkCard
              key={car.id}
              car={car}
              onCollectIncome={onCollectIncome}
              onSendForWork={onSendForWork}
              onSuccessWork={onSuccessWork}
              onSuccessCars={onSuccessCars}
            />
          ))}
        </ul>
      </SideBar>

      <SideBar isOpen={isTransferOpen} setIsOpen={setIsTransferOpen}>
        <h2
          className={
            "font-montserrat font-extrabold text-lg italic text-center mt-5 mb-5"
          }
        >
          {t("game.footer.transfer-income-title")}
        </h2>
        <p className="text-center text-sm">
          {t("game.footer.transfer-income-body")}
        </p>

        <div className="flex gap-3 mt-9 mb-6">
          <Button
            onClick={() => {
              setIsTransferOpen(false);
            }}
            className="w-full"
          >
            {t("game.footer.cancel")}
          </Button>
          <Button
            disabled={Number(userEarnings?.earnings) === 0}
            onClick={onTransferToBalance}
            variant="dark"
            className="w-full"
          >
            {t("game.footer.transfer")}
          </Button>
        </div>
      </SideBar>
    </>
  );
}
