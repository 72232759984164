import arrowRightIcon from "./arrow-right.png";
import { Card } from "../Card/Card";
import { CardBlocker, CardBlockerProps } from "../CardBlocker/CardBlocker";
import { imgTire } from "@/assets";
import { TXY, USDT } from "@/variables";
import { useTranslation } from "react-i18next";

export function CardParkCar(p: {
  name: string;
  image: string;
  profitTXY: string;
  profitUSDT: string;
  reliability: number;
  cardBlockerProps?: CardBlockerProps;
}) {
  const { t } = useTranslation("common");
  return (
    <div className="relative overflow-hidden rounded-10 border border-black-dark-card flex flex-col">
      <h2
        style={{
          backgroundImage: `url(${arrowRightIcon})`,
          backgroundSize: "1.6rem",
        }}
        className="m-3 mr-4 mb-0 font-inter font-bold text-lg bg-right bg-no-repeat"
      >
        {p.name}
      </h2>
      <img
        src={p.image}
        height={200}
        className="grow w-4/5 aspect-[26/12] z-10 mx-auto object-contain mb-1"
      />

      <div className="flex gap-1.5 z-10 p-1.5 pt-0 whitespace-pre-wrap font-medium text-xs leading-normal">
        <Card className="grow pr-6">
          <div className="text-gray-light">{t("car.profit")}</div>
          {`${t("car.upTo")} ${p.profitTXY} ${TXY} (${p.profitUSDT} ${USDT})`}
        </Card>
        <Card className="pr-4">
          <div className="text-gray-light">{t("car.reliability")}</div>
          {p.reliability}%
        </Card>
      </div>
      <div
        style={{
          backgroundImage: `url(${imgTire})`,
        }}
        className="absolute inset-0 top-1/2 bg-black-dark bg-no-repeat bg-contain"
      />
      {p.cardBlockerProps && <CardBlocker {...p.cardBlockerProps} />}
    </div>
  );
}
