import { imgTireBig } from "@/assets";
import { Lang } from "@/localization/localizationConfig";
import { TXY } from "@/variables";
import { useTranslation } from "react-i18next";
import { CarProfitList } from "./CarProfitList";
import { Card } from "../cards/Card/Card";
import { Head } from "../ui/Head";
import { capitalize, cn } from "@/utils";
import { CSSProperties } from "react";

const tireStyle: CSSProperties = {
  backgroundImage: `url(${imgTireBig})`,
};

type PofitItem = {
  txy: string;
  usdt: string;
};
export type Profits = {
  perDay: PofitItem;
  perMonth: PofitItem;
  perYear: PofitItem;
};
export function CarInfo(p: {
  price?: number | string;
  cover: string;
  reliability: number;
  descriptionRu: string;
  descriptionUa: string;
  descriptionEn: string;
  profits: Profits;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  return (
    <div>
      <div className="relative rounded-10 py-2 overflow-hidden">
        <img
          src={p.cover}
          alt=""
          className="w-full object-contain mx-auto z-10 relative"
        />

        <div className="absolute inset-0 bottom-1/2 bg-yellow-primary" />

        <div
          style={tireStyle}
          className="absolute inset-0 bottom-0 top-1/2 bg-black-dark bg-cover bg-no-repeat"
        />
      </div>

      <Head className="mt-4 mb-1 text-base">{t("car.characteristic")}</Head>

      <p className="text-xs text-gray-light">
        {p[`description${capitalize(language as Lang)}`]}
      </p>

      <Head className="mt-4 mb-1 text-base">{t("car.profit")}:</Head>

      <CarProfitList profits={p.profits} />

      <div className="flex gap-1.5 text-xs mt-4 *:flex-1">
        {p.price && (
          <Card variant="mini">
            <div className="font-bold text-base">{t("car.price")}:</div>
            {+p.price === 0 ? t("market.free") : `${p.price} ${TXY}`}
          </Card>
        )}

        <Card
          variant="mini"
          className={cn({
            "flex align-center gap-2 text-base py-3 px-4": !p.price,
          })}
        >
          <div className="font-bold text-base">{t("car.reliability")}:</div>
          {p.reliability}%
        </Card>
      </div>
    </div>
  );
}
