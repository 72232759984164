import { SVGProps } from "react";

function ArrowIcon(p: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M7.6378 18.2071C7.24728 17.8166 7.24728 17.1834 7.6378 16.7929L14.0018 10.4289C14.3923 10.0384 15.0255 10.0384 15.416 10.4289C15.8065 10.8195 15.8065 11.4526 15.416 11.8431L9.75912 17.5L15.416 23.1569C15.8065 23.5474 15.8065 24.1805 15.416 24.5711C15.0255 24.9616 14.3923 24.9616 14.0018 24.5711L7.6378 18.2071ZM26.6548 18.5L8.34491 18.5L8.34491 16.5L26.6548 16.5L26.6548 18.5Z"
        fill="#FEFEFE"
      />
    </svg>
  );
}

export default ArrowIcon;
