import { useEffect, useState } from "react";

const getTimeString = (date: Date) => {
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  return `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
};

const getStartTimer = (date: Date | null) => {
  if (date) {
    return new Date(date).getTime() - Date.now();
  }
  return 0;
};

export const Time = ({
  repairedAt,
  updateStatusCar,
}: {
  repairedAt: Date;
  updateStatusCar: () => void;
}) => {
  const [timeRemaining, setTimeRemaining] = useState(() =>
    getStartTimer(repairedAt)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prev) => {
        if (prev <= 0 || prev - 1000 < 0) {
          clearInterval(timer);
          setTimeout(() => {
            updateStatusCar();
          });
          return 0;
        }

        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [repairedAt]);

  return <span>{getTimeString(new Date(timeRemaining))}</span>;
};
