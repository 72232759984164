import { Button } from "@/components/ui/Button";
import { useTranslation } from "react-i18next";
import background from "@/assets/profile/RequestInProcess.png";

export function RequestInProcess({
  setIsOpen,
}: {
  setIsOpen: (value: boolean) => void;
}) {
  const { t } = useTranslation("common");

  return (
    <div className="text-center">
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
        }}
        className="w-full h-44 bg-no-repeat"
      />
      <div className="px-3">
        <p className="font-montserrat font-black text-lg italic mb-1">
          {t("balance.transfer.requestIsProcessed")}
        </p>
        <p className="w-3/4 mx-auto text-sm">
          {t("balance.transfer.processingTime")}
        </p>
        <Button
          variant="dark"
          className="w-full mt-9 mb-5"
          onClick={() => setIsOpen(false)}
        >
          {t("balance.transfer.ok")}
        </Button>
      </div>
    </div>
  );
}
