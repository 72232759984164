import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";
import { ReferralsStatus } from "@/api";
import { Lang } from "@/localization/localizationConfig";

const ReferralItem = ({
  data,
  isFullIcon,
}: {
  data: ReferralsStatus;
  isFullIcon: boolean;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  return (
    <div
      className={"flex p-2 items-center gap-2 bg-black-dark-card rounded-10"}
    >
      <div
        className={
          "bg-yellow-primary size-14 rounded-full bg-contain bg-center bg-no-repeat"
        }
        style={{
          backgroundImage: `url(${data.cover.file})`,
          backgroundSize: isFullIcon ? "100%" : "80%",
        }}
      />
      <div>
        <h3 className={"text-yellow-primary text-sm font-semibold mb-1"}>
          {data[`title_${language as Lang}`] ?? data.title_en}
        </h3>
        <div className={"text-gray-light text-sm"}>
          <span>{t("refs.count")}: </span>
          <span className={"text-white-light"}>
            {data.min_referral_count}
            {data.max_referral_count ? `-${data.max_referral_count}` : "+"}
          </span>
        </div>
        <p className={"text-gray-light text-sm"}>
          <span>{t("refs.totalProfit")}: </span>
          <span className={"text-white-light"}>
            {data.income_per_referral_in_percent}%
          </span>
        </p>
      </div>
    </div>
  );
};

export const ReferralInfo = () => {
  const { t } = useTranslation("common");

  const { data } = useQuery(apiQueries.getReferralsStatuses);

  return (
    <>
      <h2 className={"text-base font-semibold mb-3"}>{t("refs.refSystem")}</h2>
      <div className={"flex flex-col gap-2"}>
        {data &&
          data?.map((item, index) => {
            return (
              <ReferralItem
                key={item.id}
                data={item}
                isFullIcon={index + 1 === data.length}
              />
            );
          })}
      </div>
    </>
  );
};
