import { TFunction } from "i18next";
import { Lang } from "@/localization/localizationConfig";

type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

type Range<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

export type FormatterFn = (date: string) => string;

export type LocalizedFormatterFn = (
  date: string,
  locale: Lang,
  translations: TFunction<"common", undefined>
) => string;

export const CURRENCY = "TXY";

export const getLocalizedFormatter =
  (
    formatter: LocalizedFormatterFn,
    locale: Lang,
    translations: TFunction<"common", undefined>
  ): FormatterFn =>
  (date: string) =>
    formatter(date, locale, translations);

export const dayFormatter: LocalizedFormatterFn = (date, locale) =>
  new Date(date).toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
  });

export const weekFormatter: LocalizedFormatterFn = (date, _, translations) => {
  const dayIndex = new Date(date).getDay() as Range<0, 7>;

  return translations(`exchange.days.${dayIndex}`);
};

export const monthFormatter: LocalizedFormatterFn = (date, locale) =>
  new Date(date).toLocaleString(locale, {
    day: "2-digit",
    month: "2-digit",
  });

export const yearFormatter: LocalizedFormatterFn = (date, _, translations) => {
  const monthIndex = new Date(date).getMonth() as Range<0, 12>;

  return translations(`exchange.months.${monthIndex}`);
};

export const allTimeFormatter: LocalizedFormatterFn = (date) =>
  new Date(date).getFullYear().toString();

export const getDayTootlipText: LocalizedFormatterFn = (
  date,
  locale,
  translations
) =>
  new Date(date).toLocaleString(locale, { day: "2-digit" }) +
  " " +
  yearFormatter(date, locale, translations).toLowerCase() +
  " " +
  dayFormatter(date, locale, translations);

export const getWeekTootlipText: LocalizedFormatterFn = (
  date,
  locale,
  translations
) =>
  new Date(date).toLocaleString(locale, { day: "2-digit" }) +
  " " +
  yearFormatter(date, locale, translations).toLowerCase() +
  " " +
  allTimeFormatter(date, locale, translations);

export const getMonthTootlipText: LocalizedFormatterFn = (
  date,
  locale,
  translations
) =>
  new Date(date).toLocaleString(locale, { day: "2-digit" }) +
  " " +
  yearFormatter(date, locale, translations).toLowerCase() +
  " " +
  allTimeFormatter(date, locale, translations);

export const getYearTootlipText: LocalizedFormatterFn = (
  date,
  locale,
  translations
) =>
  yearFormatter(date, locale, translations) +
  " " +
  allTimeFormatter(date, locale, translations);

export const getAllTimeTootlipText: LocalizedFormatterFn = (
  date,
  locale,
  translations
) =>
  allTimeFormatter(date, locale, translations) +
  " " +
  translations("exchange.year");
