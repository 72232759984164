import { Button } from "@/components/ui/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  formatTime,
  getDifferenceInSeconds,
  getHoursDeclension,
} from "@/utils";
import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from "@/variables";

export function WaitForNextWorkButton({
  timerEndTime,
  onSuccess,
}: {
  timerEndTime: string;
  onSuccess: () => void;
}) {
  const {
    i18n: { language },
  } = useTranslation("common");

  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const differenceInSeconds = getDifferenceInSeconds(timerEndTime);
    setTimeLeft(differenceInSeconds);
    setIsActive(true);
  }, [timerEndTime]);

  useEffect(() => {
    const seconds = timeLeft
      ? timeLeft / SECONDS_IN_HOUR > 2
        ? SECONDS_IN_HOUR
        : timeLeft / SECONDS_IN_HOUR > 0.5
        ? SECONDS_IN_MINUTE
        : 1
      : 0;

    let timer: string | number | NodeJS.Timeout | undefined;
    if (timeLeft == null) {
      return;
    }
    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (Number(prevTime) > 0) {
            return Number(prevTime) - seconds;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, seconds * 1000);
    } else if (timeLeft === 0 || timeLeft < 0) {
      onSuccess();
      setIsActive(false);
    }

    return () => clearInterval(timer);
  }, [isActive, timeLeft]);

  return (
    <Button
      disabled
      variant="dark"
      className="w-full py-2 px-1 rounded-8 text-sm bg-gray-light-02"
    >
      {(timeLeft !== null && timeLeft <= 0) || timeLeft === null
        ? "00:00"
        : timeLeft < SECONDS_IN_HOUR / 2
        ? formatTime(Number(timeLeft))
        : getHoursDeclension(Number(timeLeft / SECONDS_IN_HOUR), language)}
    </Button>
  );
}
