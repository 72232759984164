import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

const CustomTooltip = ({
  active,
  payload,
  label,
  formatter,
}: {
  active: boolean | undefined;
  payload: Payload<ValueType, NameType>[] | undefined;
  label: string;
  formatter: (date: string) => string;
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white-light p-2 rounded-6 flex flex-col items-center text-black-dark text-xs tracking-tighter">
        <p>{formatter(label)}</p>
        <p className="font-bold">{`${payload[0].value} USDT`}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
