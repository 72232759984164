import { cn } from "@utils";
import { forwardRef, useMemo } from "react";

export const Button = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    variant?: "dark" | "light" | "green";
  }
>(({ className, variant, ...props }, ref) => {
  const classNamesByVariant = useMemo(() => {
    switch (variant) {
      case "dark":
        return "text-yellow-primary bg-black-dark";
      case "green":
        return "text-white-light bg-green-completed";
      case "light":
        return "text-black-dark bg-white-light";
      default:
        return null;
    }
  }, [variant]);

  return (
    <button
      ref={ref}
      className={cn(
        "font-inter text-base font-bold text-black-dark px-6 py-3.5 bg-yellow-primary rounded-14",
        classNamesByVariant,
        {
          "bg-gray-middle text-black-dark": props.disabled,
        },
        className
      )}
      type={"button"}
      {...props}
    />
  );
});

Button.displayName = "Button";
