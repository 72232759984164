import { BalanceField } from "@/components/BalanceField";
import { HeaderContainer } from "@/components/header/HeaderContainer";
import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import { ROUTE } from "@/config/navigation";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function Header({ unload }: { unload: () => Promise<void> }) {
  const { t } = useTranslation("common");

  const [isCloseGameOpen, setIsCloseGameOpen] = useState(false);

  const closeGame = async () => {
    await unload();
    window.location.hash = ROUTE.MARKET; // todo: another way?
    setIsCloseGameOpen(false);
  };

  return (
    <>
      <HeaderContainer>
        <h1 className="font-montserrat text-lg font-black italic">
          Taxifly Game
        </h1>
        <div className="flex items-center gap-2">
          <BalanceField className={"py-3 h-[40px]"} />
          <button
            className="bg-gray-10 rounded-full p-2"
            onClick={() => {
              setIsCloseGameOpen(true);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.47556 2.71599C8.1635 1.5907 10.3807 2.62235 10.7095 4.5171H14.001C15.5198 4.5171 16.751 5.74832 16.751 7.2671C16.751 7.68131 16.4152 8.0171 16.001 8.0171C15.5868 8.0171 15.251 7.68131 15.251 7.2671C15.251 6.57674 14.6914 6.0171 14.001 6.0171H10.751V18.5171H14.001C14.6914 18.5171 15.251 17.9574 15.251 17.2671C15.251 16.8529 15.5868 16.5171 16.001 16.5171C16.4152 16.5171 16.751 16.8529 16.751 17.2671C16.751 18.7859 15.5198 20.0171 14.001 20.0171H10.7095C10.3807 21.9118 8.1635 22.9435 6.47556 21.8182L4.47555 20.4848C3.71051 19.9748 3.25098 19.1162 3.25098 18.1967V6.33747C3.25098 5.418 3.71051 4.55936 4.47555 4.04933L6.47556 2.71599ZM17.4707 14.531C17.1778 14.2381 17.1778 13.7633 17.4707 13.4704L18.1903 12.7507H13.001C12.5868 12.7507 12.251 12.4149 12.251 12.0007C12.251 11.5865 12.5868 11.2507 13.001 11.2507H18.1903L17.4707 10.531C17.1778 10.2381 17.1778 9.76329 17.4707 9.4704C17.7636 9.17751 18.2384 9.17751 18.5313 9.4704L19.8242 10.7633C20.5076 11.4467 20.5076 12.5547 19.8242 13.2381L18.5313 14.531C18.2384 14.8239 17.7636 14.8239 17.4707 14.531Z"
                fill="#FEFEFE"
              />
            </svg>
          </button>
        </div>
      </HeaderContainer>

      <SideBar isOpen={isCloseGameOpen} setIsOpen={setIsCloseGameOpen}>
        <h2
          className={
            "font-montserrat font-extrabold text-lg italic text-center mt-5 mb-1"
          }
        >
          {t("game.header.title")}
        </h2>

        <p className={"mx-14 text-center text-gray-dark-image text-sm"}>
          {t("game.header.subtitle")}
        </p>

        <div className="flex gap-3 mt-9 mb-6">
          <Button
            className={"w-full"}
            onClick={() => setIsCloseGameOpen(false)}
          >
            {t("game.header.cancel")}
          </Button>
          <Button className={"w-full"} variant="dark" onClick={closeGame}>
            {t("game.header.yes")}
          </Button>
        </div>
      </SideBar>
    </>
  );
}
