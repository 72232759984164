import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/form/Input";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestInProcess } from "./RequestInProcess";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiMutations, apiQueries } from "@/api/queries";
import { numberInputRegex, TXY, USDT } from "@/variables";

const MIN_USDT = 200;

export function TransferForm() {
  const { t } = useTranslation("common");

  const { data: currentRate } = useQuery({
    ...apiQueries.getCurrentRate,
    refetchOnMount: false,
  });
  const { data: userData } = useQuery({
    ...apiQueries.getProfile,
    refetchOnMount: false,
  });

  const [wallet, setWallet] = useState("");
  const [amount, setAmount] = useState("");

  const [isTransferInProcess, setIsTransferInProcess] = useState(false);

  const MIN_IN_TXY =
    Math.ceil((MIN_USDT / Number(currentRate?.usdt_per_txy)) * 100) / 100;

  const REQUESTED_USDT = amount
    ? (parseFloat(amount) * Number(currentRate?.usdt_per_txy)).toFixed(5)
    : 0;

  const amountError =
    Number(userData?.balance) < parseFloat(amount)
      ? t("balance.transfer.notEnoughBalance")
      : amount && parseFloat(amount) < MIN_IN_TXY
      ? `${t("balance.transfer.min")} ${
          MIN_IN_TXY /* переведено из USDT по курсу в TXY*/
        } ${TXY}`
      : "";

  const onChangeWallet = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setWallet(value);
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (numberInputRegex.test(value)) {
      const formattedValue = value.replace(",", ".");

      const hasDot = (formattedValue.match(/\./g) || []).length;

      if (hasDot > 1) {
        return;
      }

      setAmount(formattedValue);
    }
  };

  const requestWithdrawal = useMutation({
    ...apiMutations.requestWithdrawal,
    onSuccess: () => {
      // todo: обновлять профиль? будет блокироваться баланс сразу после запроса на вывод?
      setIsTransferInProcess(true);
    },
  });

  const onSubmit = () => {
    requestWithdrawal.mutateAsync({
      requested_amount: amount,
      wallet,
    });
  };

  return (
    <>
      <div className="mt-2">
        <p className="text-gray-middle text-sm mb-4">
          {t("balance.transfer.subtitle")}
        </p>

        <Input
          className="w-full mb-4"
          label={t("balance.transfer.setWalletAddress")}
          placeholder={t("balance.transfer.setAddress")}
          value={wallet}
          onChange={onChangeWallet}
        />

        <Input
          className="w-full"
          label={t("balance.transfer.setAmountForTransfer")}
          placeholder={t("balance.transfer.setAmount")}
          value={amount}
          onChange={onChangeAmount}
          error={amountError}
          helperText={`${t("balance.transfer.min")} ${
            MIN_IN_TXY /* переведено из USDT по курсу в TXY*/
          } ${TXY}`}
        />

        <div className="h-[1px] w-full bg-white-light opacity-20 my-5" />

        <div>
          <p>{t("balance.transfer.finalAmount")}</p>
          <p>
            {REQUESTED_USDT} {USDT}
          </p>
        </div>

        <Button
          className="absolute bottom-5 left-0 right-0 mx-4"
          disabled={
            Boolean(amountError) || ((!wallet || !amount) && !amountError)
          }
          onClick={onSubmit}
        >
          {t("balance.transfer.title")}
        </Button>
      </div>

      <SideBar
        isOpen={isTransferInProcess}
        setIsOpen={setIsTransferInProcess}
        withImage
      >
        <RequestInProcess setIsOpen={setIsTransferInProcess} />
      </SideBar>
    </>
  );
}
