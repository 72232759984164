import { apiQueries } from "@/api/queries";
import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/form/Input";
import { RadioInput } from "@/components/ui/form/RadioInput";
import { numberInputRegex, TXY } from "@/variables";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const radioInputs = [
  {
    value: "USDT TRON",
    label: "USDT TRON",
  },
  {
    value: "USDT POLYGON",
    label: "USDT POLYGON",
  },
];

export function RefillForm() {
  const { t } = useTranslation("common");

  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [error, setError] = useState("");

  const { data: currentRate } = useQuery(apiQueries.getCurrentRate);

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    const value = e.target.value;

    if (numberInputRegex.test(value)) {
      const formattedValue = value.replace(",", ".");

      const hasDot = (formattedValue.match(/\./g) || []).length;

      if (hasDot > 1) {
        return;
      }

      setAmount(formattedValue);
    }
  };

  const onSubmit = () => {
    const requestedAmountInUSDT =
      parseFloat(amount) * Number(currentRate?.usdt_per_txy);

    console.log(requestedAmountInUSDT); // переводить введенный в TXY amount в USDT по курсу для пополнения
  };

  return (
    <div className="mt-2">
      <p className="text-gray-middle text-sm mb-4">
        {t("balance.refill.subtitle")}
      </p>

      <Input
        className="w-full"
        label={`${t("balance.refill.setAmount")} ${TXY}`}
        placeholder={t("balance.refill.setAmount")}
        value={amount}
        onChange={onChangeAmount}
      />

      <div className="h-[1px] w-full bg-white-light opacity-20 my-5" />

      <div>
        <p className="text-sm">{t("balance.refill.chooseCurrency")}</p>
        <ul className="flex flex-col gap-3 mt-2">
          {radioInputs.map((item) => (
            <li key={item.value}>
              <RadioInput
                item={item}
                onChange={setCurrency}
                isChecked={item.value === currency}
              />
            </li>
          ))}
        </ul>
      </div>

      <Button
        className="absolute bottom-5 left-0 right-0 mx-4"
        disabled={(!amount || !currency) && !error}
        onClick={onSubmit}
      >
        {t("balance.refill.title")}
      </Button>
    </div>
  );
}
