import {
  imgConditionsOfUse,
  imgPrivacyPolicy,
  imgReferralProgram,
  imgTasks,
  imgWallet,
  imgTransactions,
} from "@/assets/profile";
import { ROUTE } from "@/config/navigation";
import { TranslateNamespaces } from "@/localization/localizationConfig";

type NavItemProfile = {
  img: string;
  title: keyof TranslateNamespaces["common"]["profile"]["links"];
  path: (typeof ROUTE)[keyof typeof ROUTE];
};

export const NAV_ITEMS_PROFILE: NavItemProfile[] = [
  {
    title: "balance",
    path: ROUTE.BALANCE,
    img: imgWallet,
  },
  {
    title: "tasks",
    path: ROUTE.TASKS,
    img: imgTasks,
  },
  {
    title: "transactions",
    path: ROUTE.TRANSACTIONS,
    img: imgTransactions,
  },
  {
    title: "refs",
    path: ROUTE.REFS,
    img: imgReferralProgram,
  },
  {
    title: "politic",
    path: ROUTE.POLITIC,
    img: imgPrivacyPolicy,
  },
  {
    title: "terms",
    path: ROUTE.TERMS,
    img: imgConditionsOfUse,
  },
];
