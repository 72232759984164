import { cn } from "@/utils";
import { ReactNode } from "react";

export const CardProfile = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn("w-full bg-gray-10 rounded-20", className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
