import { usePlatform } from "@/hooks/usePlatform";
import { cn } from "@/utils";
import { Snackbar, SnackbarProps } from "@telegram-apps/telegram-ui";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";

type PartialSnackbarProps = Partial<SnackbarProps>;
type SnackbarCtx = {
  setShowWithProps: Dispatch<SetStateAction<PartialSnackbarProps | null>>;
  isOpened: boolean;
};

const SnackbarContext = createContext<SnackbarCtx>({} as SnackbarCtx);

export function SnackbarProvider(p: { children: React.ReactNode }) {
  const [showWithProps, setShowWithProps] =
    useState<PartialSnackbarProps | null>(null);
  const { isApplePlatform } = usePlatform();
  const { className, onClose, ...restProps } = showWithProps || {};

  return (
    <SnackbarContext.Provider
      value={{ setShowWithProps, isOpened: Boolean(showWithProps) }}
    >
      {p.children}
      {showWithProps && (
        <Snackbar
          duration={3000}
          style={{
            // @ts-expect-error no vars types
            "--tgui--surface_dark": "#fefefe",
            "--tgui--white": "#242424",
          }}
          className={cn("z-50 mb-2 text-nowrap ", {
            "mb-4": isApplePlatform,
            className,
          })}
          onClose={() => {
            setShowWithProps(null);
            onClose?.();
          }}
          {...restProps}
        />
      )}
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  return useContext(SnackbarContext);
}
