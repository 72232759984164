import { imgCoinTxy66 } from "@/assets";
import { TranslateNamespaces } from "@/localization/localizationConfig";
import { cn } from "@/utils";
import { TXY, USDT } from "@/variables";
import { useTranslation } from "react-i18next";
import { Profits } from "./CarInfo";

const translateDatesKeys: Array<keyof TranslateNamespaces["common"]["dates"]> =
  ["perDay", "perMonth", "perYear"];

export function CarProfitList(p: {
  profits: Profits;
  className?: string;
  itemClassName?: string;
  noIcons?: boolean;
}) {
  const { t } = useTranslation("common");

  return (
    <ul className={cn("text-xs font-medium space-y-1", p.className)}>
      {translateDatesKeys.map((item, index) => (
        <li
          key={index}
          style={
            p.noIcons
              ? undefined
              : {
                  backgroundImage: `url(${imgCoinTxy66})`,
                }
          }
          className={cn(
            "bg-no-repeat bg-left bg-contain",
            { "pl-5": !p.noIcons },
            p.itemClassName
          )}
        >
          {`${(+p.profits[item].txy).toFixed(2)} ${TXY} (${(+p.profits[item]
            .usdt).toFixed(2)} ${USDT} ${t(`dates.${item}`)})`}
        </li>
      ))}
    </ul>
  );
}
