import { cn } from "@/utils";

import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { ReferralInfo } from "@/api";
import { LinkReferral } from "./LinkReferral";
import { Lang } from "@/localization/localizationConfig";

type ItemProps = {
  data: {
    title: string;
    value: string | ReactNode;
  };
  isBorder: boolean;
};

const ItemIfo = ({ isBorder, data: { title, value } }: ItemProps) => {
  return (
    <div className={cn("py-3", isBorder && "border-b border-b-white-20")}>
      <h3 className={"text-sm opacity-80 mb-1"}>{title}</h3>
      {typeof value === "string" && <h3 className={"text-sm"}>{value}</h3>}
      {typeof value !== "string" && value}
    </div>
  );
};

export const BasicInfo = ({
  dataReferral,
}: {
  dataReferral: ReferralInfo | undefined;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const config: {
    title: string;
    value: ReactNode | string | number | undefined;
  }[] = [
    {
      title: t("refs.status"),
      value:
        dataReferral?.referral_program_status[`title_${language as Lang}`] ??
        "-",
    },
    { title: t("refs.count"), value: dataReferral?.referral_count || 0 },
    {
      title: t("refs.totalProfit"),
      value: `${dataReferral?.referrals_profit_amount || 0} USDT`,
    },
    {
      title: t("refs.link"),
      value: <LinkReferral link={dataReferral?.referral_link ?? ""} />,
    },
  ];

  return (
    <>
      <h2 className={"text-base font-semibold mb-3"}>{t("refs.basicInfo")}</h2>
      <div className={"px-4 rounded-10 bg-black-dark-card mb-6"}>
        {config.map((item, index) => {
          return (
            <ItemIfo
              key={index}
              isBorder={index < config.length - 1}
              data={item}
            />
          );
        })}
      </div>
    </>
  );
};
