import { USDT } from "@/variables";
import { useTranslation } from "react-i18next";
import { cn } from "@/utils";
import { PaymentType, ResponseGetTransactions, TransactionStatus } from "@/api";

type TranslationsStatus = "approved" | "inProgress" | "rejected" | "pending";

const getStatusKeys = (
  paymentType: PaymentType
): Record<TransactionStatus, TranslationsStatus> => ({
  Waiting: paymentType === "withdrawal" ? "pending" : "inProgress", // когда вывод висит в админке и еще не рассмотрен админом, будет статус Waiting "На рассмотрении"??
  Successful: "approved",
  Rejected: "rejected",
  Processing: "inProgress",
  Unpaid: "rejected",
  Cancelled: "rejected",
});

export function ListItem({ data }: { data: ResponseGetTransactions }) {
  const { t } = useTranslation("common");

  const {
    payment_type,
    withdrawal_amount,
    transfer_amount,
    status_name: status,
  } = data;

  const statusKey = getStatusKeys(payment_type)[status];

  return (
    <li className="flex items-center justify-between bg-black-dark-card/70 px-3 py-3 rounded-10">
      <span className="font-bold">
        {payment_type === "deposit" ? transfer_amount : withdrawal_amount}{" "}
        {USDT}
      </span>
      <span
        className={cn("text-sm", {
          "text-green-completed": statusKey === "approved",
          "text-yellow-primary":
            statusKey === "inProgress" || statusKey === "pending",
          "text-red": statusKey === "rejected",
        })}
      >
        {t(`transactions.status.${statusKey as TranslationsStatus}`)}
      </span>
    </li>
  );
}
