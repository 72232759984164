/**
 * Returns a component that shows an empty list message with an image.
 *
 * @param {{ text: string; img: string; }} p
 * @returns {JSX.Element}
 */
export function EmptyListInformer(p: { text: string; img: string }) {
  return (
    <div className="flex-1 flex flex-col items-center justify-center mx-auto">
      <div
        style={{
          backgroundImage: `url(${p.img})`,
        }}
        className="size-24 bg-no-repeat bg-contain bg-center mb-4 "
      />
      <p className="text-sm text-gray-middle text-center px-2 whitespace-pre-wrap">
        {p.text}
      </p>
    </div>
  );
}
