import { cn } from "@/utils";
import { useLayoutEffect, useRef } from "react";

export function FixedBottomContainer(p: {
  children: React.ReactNode;
  isNavVisible?: boolean;
}) {
  const fixedRef = useRef<HTMLDivElement>(null);
  const spaceRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    spaceRef.current!.style.height = `${fixedRef.current!.offsetHeight}px`;
  }, []);

  return (
    <>
      <div ref={spaceRef} />
      <div
        ref={fixedRef}
        className={cn("fixed left-0 right-0 bottom-0 px-layout pb-layout", {
          "bottom-nav": p.isNavVisible,
        })}
      >
        {p.children}
      </div>
    </>
  );
}
