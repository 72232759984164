import { cn } from "@/utils";
import { Button } from "./Button";

export function Tabs(p: {
  tabs: { label: string; id: string }[];
  activeTab: string;
  onChange: (tab: string) => void;
}) {
  return (
    <ul className="flex p-1 bg-black-dark-card rounded-14">
      {p.tabs.map((t) => {
        const _isActiveTab = p.activeTab === t.id;
        return (
          <li key={t.id} className="flex-1">
            <Button
              onClick={() => p.onChange(t.id)}
              variant={_isActiveTab ? "light" : undefined}
              className={cn("!rounded-12 py-3 w-full", {
                "text-white-65 bg-transparent font-normal": !_isActiveTab,
              })}
            >
              {t.label}
            </Button>
          </li>
        );
      })}
    </ul>
  );
}
