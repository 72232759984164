import { CardProfile } from "@/components/cards/CardProfile/CardProfile";
import { SideBar } from "@/components/sideBar/SideBar";
import { Button } from "@/components/ui/Button";
import { cn } from "@/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CoinIcon } from "@/components/ui/CoinIcon";
import { imgAvatarDefault } from "@/assets/profile";
import { TXY } from "@/variables";
import { useQueryAvatars } from "./refs/basicInfo/useQueryAvatars";
import { useQuery } from "@tanstack/react-query";
import { apiQueries } from "@/api/queries";

const ProfileImage = ({
  setIsOpen,
  currentAvatar,
}: {
  setIsOpen: (value: boolean) => void;
  currentAvatar: string | undefined;
}) => {
  const [selectedAvatar, setSelectedAvatar] = useState<null | string>(null);

  const { t } = useTranslation("common");

  const closeSideBar = () => {
    setSelectedAvatar(null);
    setIsOpen(false);
  };

  const { avatars, changeAvatar } = useQueryAvatars({ closeSideBar });

  const onSubmit = () => {
    if (selectedAvatar && selectedAvatar !== currentAvatar) {
      return changeAvatar(selectedAvatar);
    }
    return closeSideBar();
  };

  return (
    <>
      <h2
        className={
          "font-montserrat font-extrabold text-lg italic text-center my-3"
        }
      >
        {t("profile.avatar")}
      </h2>
      <div className={"grid grid-cols-3 gap-2"}>
        {avatars &&
          avatars.results.map((item) => {
            const isActive = (selectedAvatar || currentAvatar) === item.id;
            return (
              <img
                onClick={() => setSelectedAvatar(item.id)}
                key={item.id}
                src={item.file}
                className={cn(
                  "rounded-20",
                  isActive ? "bg-yellow-primary" : "bg-gray-light-01"
                )}
                alt=""
              />
            );
          })}
      </div>
      <Button
        variant={"dark"}
        className={"w-full mt-12 mb-6"}
        onClick={onSubmit}
      >
        {t("profile.apply")}
      </Button>
    </>
  );
};

export const ProfileInfo = () => {
  const [isEditImageProfile, setIsEditImageProfile] = useState(false);
  const { t } = useTranslation("common");

  const { data } = useQuery(apiQueries.getProfile);

  return (
    <>
      <CardProfile className={"p-2 flex gap-3 mb-5"}>
        {/* <div className={"relative"}> */}
        <button
          className="bg-yellow-primary rounded-14 relative"
          onClick={() => setIsEditImageProfile(!isEditImageProfile)}
        >
          <img
            src={data?.avatar?.file || imgAvatarDefault}
            className={"size-20 rounded-14"} // relative -bottom-[1px]
          />

          <div className="flex items-center justify-center absolute bottom-1 right-1 backdrop-blur-sm size-6 rounded-full bg-black-dark bg-opacity-60 ">
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.0532227 10.8604L0.0858062 10.4103C0.11839 9.93867 0.319465 7.54292 1.07489 6.78706C1.48156 6.39011 2.02833 6.16949 2.59661 6.17306C3.16489 6.17664 3.70885 6.40411 4.11049 6.80615C4.51214 7.20819 4.73908 7.75237 4.7421 8.32066C4.74511 8.88894 4.52395 9.4355 4.1266 9.84177C3.37117 10.5972 0.975852 10.7978 0.502962 10.8304L0.0532227 10.8604ZM9.94663 0.953318C9.69641 0.71438 9.36375 0.581055 9.01778 0.581055C8.67181 0.581055 8.33914 0.71438 8.08893 0.953318L3.58082 5.46314C4.01189 5.60982 4.40372 5.85311 4.72636 6.17441C5.049 6.49571 5.29391 6.88652 5.44237 7.31698L9.94663 2.81058C10.1925 2.56408 10.3306 2.23012 10.3306 1.88195C10.3306 1.53378 10.1925 1.19982 9.94663 0.953318Z"
                fill="#BBBBBB"
              />
            </svg>
          </div>
        </button>
        {/* </div> */}
        <div>
          <p className={"text-gray-light my-1.5"}>{data?.username}</p>
          <div className="flex items-center gap-1.5 font-bold text-md">
            <h2>{`${t("profile.balance")}:`}</h2>

            <CoinIcon />

            <p>
              {data?.balance} {TXY}
            </p>
          </div>
        </div>
      </CardProfile>

      <SideBar isOpen={isEditImageProfile} setIsOpen={setIsEditImageProfile}>
        <ProfileImage
          currentAvatar={data?.avatar?.id}
          setIsOpen={setIsEditImageProfile}
        />
      </SideBar>
    </>
  );
};
