import { SDKProvider } from "@telegram-apps/sdk-react";
// import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { type FC, useEffect, Suspense } from "react";

import { App } from "@/components/App.tsx";
import { ErrorBoundary } from "@/components/ErrorBoundary.tsx";

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>An unhandled error occurred:</p>
    <blockquote>
      <code>
        {error instanceof Error
          ? error.message
          : typeof error === "string"
          ? error
          : JSON.stringify(error)}
      </code>
    </blockquote>
  </div>
);

const debug = import.meta.env.VITE_DEBUG === "true";

const Inner: FC = () => {
  // const manifestUrl = useMemo(() => {
  //   return new URL("tonconnect-manifest.json", window.location.href).toString();
  // }, []);

  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    if (debug) {
      import("eruda").then((lib) => lib.default.init());
    }
  }, [debug]);

  return (
    // <TonConnectUIProvider manifestUrl={manifestUrl}>
    <SDKProvider acceptCustomStyles debug={debug}>
      <App />
    </SDKProvider>
    // </TonConnectUIProvider>
  );
};

export const Root: FC = () => (
  <Suspense fallback="Загрузка...">
    <ErrorBoundary fallback={ErrorBoundaryError}>
      <Inner />
    </ErrorBoundary>
  </Suspense>
);
